import { createAction, props } from '@ngrx/store';
import {
  Agreement,
  LoginRequest,
  LoginResponse,
  User,
  UserSignedAgreement,
} from '@simx/shared/models';

export enum ActionType {
  AuthenticationFailed = '[Authentication] Authentication Failed',
  AuthenticationSucceeded = '[Authentication] Authentication Succeeded',
  DeleteUserSignedAgreementFailed = '[Authentication] Delete User Signed Agreement Failed',
  DeleteUserSignedAgreementSucceeded = '[Authentication] Delete User Signed Agreement Succeeded',
  LoginFailed = '[Authentication] Login Failed',
  LoginSucceeded = '[Authentication] Login Succeeded',
  LogoutFailed = '[Authentication] Logout Failed',
  LogoutSucceeded = '[Authentication] Logout Succeeded',
  RequestDeleteUserSignedAgreement = '[Authentication] Request Delete User Signed Agreement',
  RequestLogin = '[Authentication] Request Login',
  RequestLogout = '[Authentication] Request Logout',
  RequestSignAgreement = '[User] Request Sign Agreement',
  SetToken = '[Authentication] Set Token',
  SetUser = '[Authentication] Set User',
  SignAgreementFailed = '[User] Sign Agreement Failed',
  SignAgreementSucceeded = '[User] Sign Agreement Succeeded',
  UnsignedAgreementsCheckFailed = '[Authentication] Unsigned Agreements Check Failed',
  UnsignedAgreementsCheckSucceeded = '[Authentication] Unsigned Agreements Check Succeeded',
}

export const authenticationFailed = createAction(
  ActionType.AuthenticationFailed,
  props<{ error: string }>(),
);
export const authenticationSucceeded = createAction(
  ActionType.AuthenticationSucceeded,
  props<{ data: LoginResponse }>(),
);
export const deleteUserSignedAgreementFailed = createAction(
  ActionType.DeleteUserSignedAgreementFailed,
  props<{ error: string }>(),
);
export const deleteUserSignedAgreementSucceeded = createAction(
  ActionType.DeleteUserSignedAgreementSucceeded,
  props<{ data: { agreementId: string; userId: string } }>(),
);
export const loginFailed = createAction(
  ActionType.LoginFailed,
  props<{ error: string }>(),
);
export const loginSucceeded = createAction(ActionType.LoginSucceeded);
export const logoutFailed = createAction(
  ActionType.LogoutFailed,
  props<{ error: string }>(),
);
export const logoutSucceeded = createAction(ActionType.LogoutSucceeded);
export const requestDeleteUserSignedAgreement = createAction(
  ActionType.RequestDeleteUserSignedAgreement,
  props<{ data: { agreementId: string; userId: string } }>(),
);
export const requestLogin = createAction(
  ActionType.RequestLogin,
  props<{ data: LoginRequest }>(),
);
export const requestLogout = createAction(ActionType.RequestLogout);
export const requestSignAgreement = createAction(
  ActionType.RequestSignAgreement,
  props<{ agreementId: string }>(),
);
export const setToken = createAction(
  ActionType.SetToken,
  props<{ token: string }>(),
);
export const setUser = createAction(
  ActionType.SetUser,
  props<{ user: User }>(),
);
export const signAgreementFailed = createAction(
  ActionType.SignAgreementFailed,
  props<{ error: string }>(),
);
export const signAgreementSucceeded = createAction(
  ActionType.SignAgreementSucceeded,
  props<{ data: UserSignedAgreement }>(),
);
export const unsignedAgreementsCheckFailed = createAction(
  ActionType.UnsignedAgreementsCheckFailed,
  props<{ error: string }>(),
);
export const unsignedAgreementsCheckSucceeded = createAction(
  ActionType.UnsignedAgreementsCheckSucceeded,
  props<{ data: Array<Agreement> }>(),
);
