import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInStaticPositionAnimation,
  fadeOutStaticPositionAnimation,
} from '@simx/shared/animations';
import { LoadingIndicatorStyles } from '../../../constants';
import {
  CheckSelectListItemInterface,
  EntityLabelsInterface,
} from '../../../interfaces';

@Component({
  selector: 'check-select-list',
  templateUrl: './check-select-list.component.html',
  styleUrls: ['./check-select-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  animations: [
    trigger('fadeInOutStaticPositionAnimation', [
      transition(':enter', [
        useAnimation(fadeInStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class CheckSelectListComponent implements OnChanges {
  @ViewChild('bulkSelectToggle') bulkSelectToggleElement: ElementRef;

  @Input() displayHeader: boolean = false;

  @Input() isLoadingItems: boolean = false;
  @Input() hasLoadedItems: boolean = false;

  @Input() items: Array<CheckSelectListItemInterface>;

  @Input('displaySelectAll') displaySelectAllInput: boolean = true;
  @Input() displayItemCount: boolean = true;

  totalItemCount: number = 0;
  visibleItemCount: number = 0;

  checkedItemValues: Array<string> = [];

  @Input() entityLabels: EntityLabelsInterface;

  @Output('checkedItemsChange') changeEvent: EventEmitter<Array<string>> =
    new EventEmitter<Array<string>>();

  get displaySelectAll() {
    return this.displaySelectAllInput && this.items.length;
  }

  get displayFooter() {
    return this.displaySelectAllInput || this.displayItemCount;
  }

  loadingIndicatorStyles = LoadingIndicatorStyles;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.checkedItemValues = [];
      this.totalItemCount = 0;
      this.visibleItemCount = 0;

      changes.items.currentValue.forEach(item => {
        this.totalItemCount++;
        if (item.visible) {
          this.visibleItemCount++;
        }
        if (item.checked) {
          this.checkedItemValues.push(item.value);
        }
      });
    }
  }

  handleToggleListItemAction(itemValue: string) {
    this.toggleListItem(itemValue);
  }

  handleSelectAllListItemsAction() {
    this.bulkSelectToggleElement.nativeElement.blur();

    this.selectAllListItems();
  }

  toggleListItem(itemValue: string) {
    const listItem = this.items.find(item => item.value === itemValue);
    if (listItem.disabled) {
      return;
    }

    let newCheckedItemValues;

    if (this.checkedItemValues.includes(itemValue)) {
      newCheckedItemValues = this.checkedItemValues.filter(
        value => value !== itemValue,
      );
    } else {
      newCheckedItemValues = [...this.checkedItemValues, itemValue];
    }

    this.emitCheckedItemValuesChange(newCheckedItemValues);
  }

  selectAllListItems() {
    const listItemValuesToAdd = [];
    for (let item of this.items) {
      if (item.visible && !item.disabled) {
        listItemValuesToAdd.push(item.value);
      }
    }
    const newCheckedItemValues = [
      ...new Set([...this.checkedItemValues, ...listItemValuesToAdd]),
    ];
    this.emitCheckedItemValuesChange(newCheckedItemValues);
  }

  emitCheckedItemValuesChange(itemValues: Array<string>) {
    this.changeEvent.emit(itemValues);
  }
}
