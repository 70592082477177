import { Component } from '@angular/core';

@Component({
  selector: 'splash-view',
  templateUrl: './splash-view.component.html',
  styleUrls: ['./splash-view.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class SplashViewComponent {}
