import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInFromTopAnimation,
  fadeOutToTopAnimation,
} from '@simx/shared/animations';
import { DropDownMenuItem } from '../../../models';
import { UIThemes } from '@simx/shared/constants';
import { IconButtonIconTypes } from '@simx/modules/ui-elements/constants';

@Component({
  selector: 'drop-down-menu',
  templateUrl: './drop-down-menu.component.html',
  styleUrls: ['./drop-down-menu.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInFromTopOutToTopAnimation', [
      transition(':enter', [
        useAnimation(fadeInFromTopAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToTopAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class DropDownMenuComponent implements OnDestroy {
  @Input() items: Array<DropDownMenuItem> = [];
  @Input() disabled: boolean = false;
  @Input() theme: UIThemes;

  @Output() itemSelect: EventEmitter<string> = new EventEmitter<string>();

  menuState: boolean = false;

  iconButtonIconTypes = IconButtonIconTypes;
  get triggerIconButtonClasses() {
    const classes = [];

    classes.push(this.theme);

    if (this.menuState) {
      classes.push('simx-active');
    }

    return classes;
  }

  get menuClasses() {
    return ['simx-drop-down-menu--menu', this.theme];
  }

  windowClickListener: () => void;

  constructor(private renderer: Renderer2) {}

  handleMenuTriggerClick(event: Event) {
    event.stopPropagation();

    if (this.disabled) {
      return;
    }

    this.toggleMenu();
  }

  handleMenuItemSelect(event: Event, selectedId: string) {
    event.stopPropagation();

    const item = this.items.find(item => item.id === selectedId);
    if (item.disabled) {
      return;
    }

    this.itemSelect.emit(selectedId);
    this.toggleMenu(false);
  }

  toggleMenu(state?: boolean) {
    const newState = state !== undefined ? state : !this.menuState;
    this.menuState = newState;

    if (newState) {
      this.windowClickListener = this.renderer.listen(
        'window',
        'click',
        (event: Event) => {
          this.toggleMenu(false);
        },
      );
    } else {
      if (this.windowClickListener) {
        this.windowClickListener();
      }
    }
  }

  ngOnDestroy() {
    if (this.windowClickListener) {
      this.windowClickListener();
    }
  }
}
