import { Component, Input, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { AuthenticationService } from '@simx/modules/authentication/services';
import { TaxonomyService } from '@simx/modules/taxonomy/services';
import { EntityDetailDisplayModes } from '@simx/shared/constants';
import { Taxonomies } from '@simx/modules/taxonomy/constants';
import { TaxonomyItem } from '@simx/shared/models';
import { CheckListSelectItem } from '@simx/modules/ui-elements/models';

@Component({
  selector: 'permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PermissionsListComponent,
    },
  ],
})
export class PermissionsListComponent implements ControlValueAccessor, OnInit {
  @Input() id: string;
  @Input() displayMode: EntityDetailDisplayModes;
  @Input() permissions: Array<string>;
  @Input() taxonomyName: Taxonomies;
  @Input() institutionId: string;

  allowedPermissions: Array<string>;
  hiddenPermissions: Array<string>;

  form: FormGroup;
  checkListSelectItems: Array<CheckListSelectItem> = [];

  displayModes = EntityDetailDisplayModes;

  onChange = _ => {};
  onTouched = () => {};
  touched: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private taxonomyService: TaxonomyService,
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.taxonomyService
      .getTaxonomy(this.taxonomyName)
      .then((taxonomy: Array<TaxonomyItem>) => {
        taxonomy.forEach(taxonomyItem => {
          if (this.institutionId) {
            if (
              this.authenticationService.isAllowedForInstitution(
                taxonomyItem.term,
                this.institutionId,
              )
            ) {
              this.checkListSelectItems.push({
                value: taxonomyItem.term,
                label: taxonomyItem.label,
              });
            }
          } else {
            if (this.authenticationService.isAllowed(taxonomyItem.term)) {
              this.checkListSelectItems.push({
                value: taxonomyItem.term,
                label: taxonomyItem.label,
              });
            }
          }
        });
      });
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      permissions: [[]],
    });
    this.form.valueChanges.subscribe(data => {
      this.allowedPermissions = data.permissions;
      this.permissions = [
        ...this.hiddenPermissions,
        ...this.allowedPermissions,
      ];
      this.onChange(this.permissions);
    });
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  writeValue(value: Array<string>) {
    this.permissions = value;

    this.allowedPermissions = [];
    this.hiddenPermissions = [];

    this.permissions.forEach(permission => {
      if (this.institutionId) {
        if (
          this.authenticationService.isAllowedForInstitution(
            permission,
            this.institutionId,
          )
        ) {
          this.allowedPermissions.push(permission);
        } else {
          this.hiddenPermissions.push(permission);
        }
      } else {
        if (this.authenticationService.isAllowed(permission)) {
          this.allowedPermissions.push(permission);
        } else {
          this.hiddenPermissions.push(permission);
        }
      }
    });

    this.form.controls.permissions.setValue(this.allowedPermissions);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
