import {
  Component,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { EntityDetailDisplayModes } from '@simx/shared/constants';

@Component({
  selector: 'entity-detail-attribute',
  templateUrl: './entity-detail-attribute.component.html',
  styleUrls: ['./entity-detail-attribute.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
})
export class EntityDetailAttributeComponent {
  @Input() displayMode: EntityDetailDisplayModes;
  displayModes = EntityDetailDisplayModes;

  @Input() layout: any;
  @Input() label: TemplateRef<any>;
  @Input() value: TemplateRef<any>;

  get classes() {
    const classNames = [];

    classNames.push(this.displayMode);

    if (this.layout) {
      switch (this.displayMode) {
        case EntityDetailDisplayModes.View:
          classNames.push(this.layout.view);
          break;
        case EntityDetailDisplayModes.Edit:
          classNames.push(this.layout.edit);
          break;
        case EntityDetailDisplayModes.Create:
          classNames.push(this.layout.create);
          break;
        default:
      }
    }

    return classNames;
  }
}
