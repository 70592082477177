import { ActionReducer, INIT } from '@ngrx/store';
import { State as RootState } from './root-store.state';

export const hydrationMetaReducer = (
  reducer: ActionReducer<RootState>,
): ActionReducer<RootState> => {
  return (state, action) => {
    if (action.type === INIT) {
      const storageValue = localStorage.getItem('simxState');
      if (storageValue) {
        try {
          return JSON.parse(storageValue);
        } catch {
          localStorage.removeItem('simxState');
        }
      }
    }
    const nextState = reducer(state, action);
    localStorage.setItem('simxState', JSON.stringify(nextState));
    return nextState;
  };
};
