import { AppNavigationItem } from '../models';
import { GenericPermissions } from '@simx/modules/authentication/constants';

export const userNavigationItems: Array<AppNavigationItem> = [
  {
    label: 'My Account',
    path: '/account',
    permissions: [GenericPermissions.IsAuthenticated],
  },
  {
    label: 'Log Out',
    path: '/logout',
    permissions: [GenericPermissions.IsAuthenticated],
  },
];
