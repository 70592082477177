import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Download } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class DownloadsDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getDownloadsForReleaseTag(releaseTag: string): Promise<Array<Download>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.downloadsUrl(releaseTag);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const downloads = response.map(item => new Download(item));

          resolve(downloads);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
