import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TaxonomyModule } from '@simx/modules/taxonomy';
import { allComponents, publicComponents } from './components';

@NgModule({
  declarations: [...allComponents],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TaxonomyModule],
  exports: [...publicComponents],
})
export class UIElementsModule {}
