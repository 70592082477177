import { Agreement, User } from '@simx/shared/models';

export interface State {
  isAcceptingAgreement: boolean;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  isDeletingUserSignedAgreement: {
    userId: string;
    agreementId: string;
  } | null;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  token: string;
  unsignedAgreements: Array<Agreement>;
  user: User | null;
}

export const initialState: State = {
  isAcceptingAgreement: false,
  isAuthenticated: false,
  isAuthenticating: false,
  isDeletingUserSignedAgreement: null,
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  token: null,
  unsignedAgreements: [],
  user: null,
};
