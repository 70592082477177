import { createAction, props } from '@ngrx/store';
import { Institution } from '@simx/shared/models';

export enum ActionTypes {
  CheckInstitutionListLoaded = '[Institution] Check Institution List Loaded',
  LoadInstitutionListRequested = '[Institution] Load Institution List Requested',
  LoadInstitutionListSucceeded = '[Institution] Load Institution List Succeeded',
  LoadInstitutionListFailed = '[Institution] Load Institution List Failed',
  LoadInstitutionList = '[Institution] Load Institution List',
  AddInstitutionToInstitutionList = '[Institution] Add Institution To Institution List',
  UpdateInstitutionInInstitutionList = '[Institution] Update Institution In Institution List',
  RemoveInstitutionFromInstitutionList = '[Institution] Remove Institution From Institution List',
  ClearInstitutionStoreAndLoadInstitutionList = '[Institution] Clear Institution Store And Load Institution List',
  ClearInstitutionStore = '[Institution] Clear Institution Store',
}

export const checkInstitutionListLoaded = createAction(
  ActionTypes.CheckInstitutionListLoaded,
);
export const loadInstitutionListRequested = createAction(
  ActionTypes.LoadInstitutionListRequested,
);
export const loadInstitutionListSucceeded = createAction(
  ActionTypes.LoadInstitutionListSucceeded,
  props<{ institutions: Array<Institution> }>(),
);
export const loadInstitutionListFailed = createAction(
  ActionTypes.LoadInstitutionListFailed,
  props<{ error: string }>(),
);
export const loadInstitutionList = createAction(
  ActionTypes.LoadInstitutionList,
  props<{ institutions: Array<Institution> }>(),
);
export const addInstitutionToInstitutionList = createAction(
  ActionTypes.AddInstitutionToInstitutionList,
  props<{ institution: Institution }>(),
);
export const updateInstitutionInInstitutionList = createAction(
  ActionTypes.UpdateInstitutionInInstitutionList,
  props<{ institution: Institution }>(),
);
export const removeInstitutionFromInstitutionList = createAction(
  ActionTypes.RemoveInstitutionFromInstitutionList,
  props<{ institutionId: string }>(),
);
export const clearInstitutionStoreAndLoadInstitutionList = createAction(
  ActionTypes.ClearInstitutionStoreAndLoadInstitutionList,
);
export const clearInstitutionStore = createAction(
  ActionTypes.ClearInstitutionStore,
);
