export class SimulationOptionTaxonomy {
  static terms = new Map([
    [
      'TELEPORTATION',
      {
        value: 'teleportation',
        label: 'Teleportation',
      },
    ],
  ]);
}
