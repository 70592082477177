import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from '@simx/modules/authentication/guards';
import { Permissions } from '@simx/shared/constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@simx/modules/dashboard/dashboard.module').then(
        m => m.DashboardModule,
      ),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('@simx/modules/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'scenarios',
    loadChildren: () =>
      import('@simx/modules/scenario/scenario.module').then(
        m => m.ScenarioModule,
      ),
    canActivate: [AuthorizedGuard],
    data: {
      permissions: [
        Permissions.AccessScenarios,
        Permissions.AdministerAllScenarios,
        Permissions.AdministerScenarios,
      ],
    },
  },
  {
    path: 'scenario-packages',
    loadChildren: () =>
      import('@simx/modules/scenario-package/scenario-package.module').then(
        m => m.ScenarioPackageModule,
      ),
  },
  {
    path: 'institutions',
    loadChildren: () =>
      import('@simx/modules/institution/institution.module').then(
        m => m.InstitutionModule,
      ),
    canActivate: [AuthorizedGuard],
    data: {
      permissions: [
        Permissions.AdministerAssociatedInstitutions,
        Permissions.AdministerAllInstitutions,
      ],
    },
  },
  {
    path: 'downloads',
    loadChildren: () =>
      import('@simx/modules/downloads/downloads.module').then(
        m => m.DownloadsModule,
      ),
    canActivate: [AuthorizedGuard],
    data: {
      permissions: [],
    },
  },
  {
    path: 'help',
    loadChildren: () =>
      import('@simx/modules/help/help.module').then(m => m.HelpModule),
    canActivate: [AuthorizedGuard],
    data: {
      permissions: [],
    },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@simx/modules/account/account.module').then(m => m.AccountModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
