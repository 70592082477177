import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInStaticPositionAnimation,
  fadeOutStaticPositionAnimation,
} from '@simx/shared/animations';
import { LoadingIndicatorStyles } from '../../../constants';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutStaticPosition', [
      transition(':enter', [
        useAnimation(fadeInStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class ActionButtonComponent {
  @Input() type: string = 'button';
  @Input('classes') additionalClasses: Array<string> = [];
  @Input() disabled: boolean = false;
  @Input() working: boolean = false;
  @Input() theme: string = 'simx-theme--bright-accent';

  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  loaderStyles = LoadingIndicatorStyles;

  get classes() {
    const classNames = ['simx-action-button', this.theme];
    if (this.working) {
      classNames.push('simx-working');
    }
    classNames.push(...this.additionalClasses);
    return classNames.join(' ');
  }

  handleClick(event) {
    event.stopPropagation();

    this.click.emit(event);
  }
}
