import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@simx/env';
import { hydrationMetaReducer } from './root-store.reducer';

const metaReducers: MetaReducer[] = [hydrationMetaReducer];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class RootStoreModule {}

// const ngrxModules = [
//   StoreModule.forRoot(
//     reducers,
//     {
//       metaReducers,
//       runtimeChecks: {
//         strictStateImmutability: true,
//         strictActionImmutability: true
//       }
//     }
//   ),
//   !environment.production ? StoreDevtoolsModule.instrument() : []
// ];
//
// const metaReducers: MetaReducer<any, any>[] = !environment.production
//   ? [fromGlobal.setRootState, localStorageSyncReducer]
//   : [localStorageSyncReducer];
