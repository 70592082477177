import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timestamp' })
export class TimestampPipe implements PipeTransform {
  transform(
    value: string,
    displayDate: boolean = true,
    displayTime: boolean = true,
  ): string {
    const dateValue = new Date(value);

    let dateFormatOptions = {};
    if (displayDate) {
      dateFormatOptions = {
        ...dateFormatOptions,
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };
    }
    if (displayTime) {
      dateFormatOptions = {
        ...dateFormatOptions,
        hour: 'numeric',
        minute: '2-digit',
      };
    }

    return dateValue.toLocaleString('en-US', dateFormatOptions);
  }
}
