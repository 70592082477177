import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { ScenarioPackage } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioPackageDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getScenarioPackages(): Promise<Array<ScenarioPackage>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackagesUrl();

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const scenarioPackages = response.map(
            item => new ScenarioPackage(item),
          );

          resolve(scenarioPackages);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getScenarioPackage(
    scenarioPackageId: string,
    includes?: any,
  ): Promise<ScenarioPackage> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageUrl(
        scenarioPackageId,
        includes,
      );

      this.http
        .get(url)
        .toPromise()
        .then((response: any) => {
          const scenarioPackage = new ScenarioPackage(response);

          resolve(scenarioPackage);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  createScenarioPackage(
    scenarioPackage: ScenarioPackage,
  ): Promise<ScenarioPackage> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageUrl();
      const body = scenarioPackage;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const scenarioPackage = new ScenarioPackage(response);

          resolve(scenarioPackage);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  updateScenarioPackage(
    scenarioPackage: ScenarioPackage,
    includes?: any,
  ): Promise<ScenarioPackage> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageUrl(null, includes);
      const body = scenarioPackage;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: any) => {
          const scenarioPackage = new ScenarioPackage(response);

          resolve(scenarioPackage);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  deleteScenarioPackage(scenarioPackageId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageUrl(scenarioPackageId);

      this.http
        .delete(url)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
