import { createAction, props } from '@ngrx/store';
import { TaxonomyItem } from '@simx/shared/models';

export enum ActionTypes {
  CheckTaxonomyLoaded = '[Taxonomy] Check Taxonomy Loaded',
  LoadTaxonomyRequested = '[Taxonomy] Load Taxonomy Requested',
  LoadTaxonomySucceeded = '[Taxonomy] Load Taxonomy Succeeded',
  LoadTaxonomyFailed = '[Taxonomy] Load Taxonomy Failed',
  LoadTaxonomy = '[Taxonomy] Load Taxonomy',
  ClearTaxonomies = '[Taxonomy] Clear Taxonomies',
}

export const checkTaxonomyLoaded = createAction(
  ActionTypes.CheckTaxonomyLoaded,
  props<{ taxonomyName: string }>(),
);
export const loadTaxonomyRequested = createAction(
  ActionTypes.LoadTaxonomyRequested,
  props<{ taxonomyName: string }>(),
);
export const loadTaxonomySucceeded = createAction(
  ActionTypes.LoadTaxonomySucceeded,
  props<{
    taxonomyName: string;
    taxonomyItems: Array<TaxonomyItem>;
  }>(),
);
export const loadTaxonomyFailed = createAction(
  ActionTypes.LoadTaxonomyFailed,
  props<{
    taxonomyName: string;
    error: string;
  }>(),
);
export const loadTaxonomy = createAction(
  ActionTypes.LoadTaxonomy,
  props<{
    taxonomyName: string;
    taxonomyItems: Array<TaxonomyItem>;
  }>(),
);
export const clearTaxonomies = createAction(ActionTypes.ClearTaxonomies);
