import { Pipe, PipeTransform } from '@angular/core';
import { Institution } from '@simx/shared/models';

@Pipe({ name: 'institutionExpirationWindow' })
export class InstitutionExpirationWindowPipe implements PipeTransform {
  transform(institution: Institution): number {
    const { expiration } = institution;
    if (!expiration) {
      return Infinity;
    } else {
      const institutionExpirationDate = new Date(expiration);
      const windowInDays = Math.round(
        (institutionExpirationDate.getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24),
      );
      return windowInDays;
    }
  }
}
