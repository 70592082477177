import { ScenarioRelease } from './scenario-release.model';
import { ScenarioReleaseTag } from './scenario-release-tag.model';

export class Scenario {
  createdAt?: string;
  createdByUserId?: string;
  globalAuthor?: string;
  globalName?: string;
  releases?: Array<ScenarioRelease>;
  releaseTags?: Array<ScenarioReleaseTag>;
  repositoryFile?: string;
  scenarioId?: string;
  tags?: Array<string>;
  updatedAt?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('createdAt')) {
        this.createdAt = data.createdAt;
      }
      if (data.hasOwnProperty('createdByUserId')) {
        this.createdByUserId = data.createdByUserId;
      }
      if (data.hasOwnProperty('globalAuthor')) {
        this.globalAuthor = data.globalAuthor;
      }
      if (data.hasOwnProperty('globalName')) {
        this.globalName = data.globalName;
      }
      if (data.hasOwnProperty('releases')) {
        this.releases = data.releases.map(item => new ScenarioRelease(item));
      }
      if (data.hasOwnProperty('releaseTags')) {
        this.releaseTags = data.releaseTags.map(
          item => new ScenarioReleaseTag(item),
        );
      }
      if (data.hasOwnProperty('repositoryFile')) {
        this.repositoryFile = data.repositoryFile;
      }
      if (data.hasOwnProperty('scenarioId')) {
        this.scenarioId = data.scenarioId;
      }
      if (data.hasOwnProperty('tags')) {
        this.tags = data.tags;
      }
      if (data.hasOwnProperty('updatedAt')) {
        this.updatedAt = data.updatedAt;
      }
    }
  }
}
