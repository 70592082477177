import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataService } from './data.service';
import { Agreement } from '@simx/shared/models';
import * as AuthenticationStoreActions from '../../modules/authentication/store/authentication-store.actions';
import * as AuthenticationStoreSelectors from '../../modules/authentication/store/authentication-store.selectors';

@Injectable({ providedIn: 'root' })
export class AgreementDataService {
  constructor(
    private readonly _dataService: DataService,
    private readonly _http: HttpClient,
    private store: Store,
    private actions$: Actions,
  ) {}

  getAgreement(agreementId: string): Promise<Agreement> {
    return new Promise((resolve, reject) => {
      const url = this._dataService.agreementUrl(agreementId);

      this._http
        .get(url)
        .toPromise()
        .then((response: Agreement) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  requestDeleteUserSignedAgreement(userId: string, agreementId: string) {
    this.store.dispatch(
      AuthenticationStoreActions.requestDeleteUserSignedAgreement({
        data: {
          agreementId,
          userId,
        },
      }),
    );
  }

  deleteUserSignedAgreement(userId: string, agreementId: string) {
    const url = this._dataService.deleteUserSignedAgreementUrl(
      userId,
      agreementId,
    );

    return this._http.request('delete', url).pipe(
      map(() =>
        AuthenticationStoreActions.deleteUserSignedAgreementSucceeded({
          data: {
            userId,
            agreementId,
          },
        }),
      ),
      catchError((error: any) =>
        of(
          AuthenticationStoreActions.deleteUserSignedAgreementFailed({
            error: error.message,
          }),
        ),
      ),
    );
  }

  getIsDeletingUserSignedAgreement$(): Observable<{
    userId: string;
    agreementId: string;
  } | null> {
    return new Observable((observer: any) => {
      this.store
        .select(
          AuthenticationStoreSelectors.selectIsDeletingUserSignedAgreement,
        )
        .subscribe((state: { userId: string; agreementId: string } | null) => {
          observer.next(state);
        });
    });
  }

  getDeleteUserSignedAgreementSucceeded$(): Observable<{
    userId: string;
    agreementId: string;
  }> {
    return new Observable((observer: any) => {
      this.actions$
        .pipe(
          ofType(
            AuthenticationStoreActions.ActionType
              .DeleteUserSignedAgreementSucceeded,
          ),
        )
        .subscribe(({ data }) => {
          observer.next(data);
        });
    });
  }

  getDeleteUserSignedAgreementFailed$(): Observable<string> {
    return new Observable((observer: any) => {
      this.actions$
        .pipe(
          ofType(
            AuthenticationStoreActions.ActionType
              .DeleteUserSignedAgreementFailed,
          ),
        )
        .subscribe(({ error }) => {
          observer.next(error);
        });
    });
  }
}
