import { Institution } from '@simx/shared/models';

export interface State {
  entityList: Array<Institution>;
  isLoadingEntityList: boolean;
  hasLoadedEntityList: boolean;
  loadEntityListError: string;
}

export const initialState: State = {
  entityList: [],
  isLoadingEntityList: false,
  hasLoadedEntityList: false,
  loadEntityListError: null,
};
