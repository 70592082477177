import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class TagComponent {
  @Input() allowCheck: boolean = false;
  @Input() allowRemove: boolean = false;
  @Input() checked: boolean = false;
  @Input() styleClasses: Array<string> = [];

  @Output('check') checkEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output('remove') removeEvent: EventEmitter<void> = new EventEmitter<void>();

  get cssClasses() {
    const cssClasses = ['simx-tag', ...this.styleClasses];
    if (this.allowCheck) {
      cssClasses.push('simx-checkable');
    }
    if (this.checked) {
      cssClasses.push('simx-checked');
    }
    return cssClasses;
  }

  handleCheckAction() {
    if (this.allowCheck) {
      this.checkEvent.emit(!this.checked);
    }
  }

  handleRemoveAction() {
    this.removeEvent.emit();
  }
}
