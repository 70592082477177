import { Action, createReducer, on } from '@ngrx/store';
import * as AuthenticationStoreActions from './authentication-store.actions';
import { State, initialState } from './authentication-store.state';

const authenticationReducer = createReducer(
  initialState,
  on(AuthenticationStoreActions.authenticationFailed, state => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: false,
  })),
  on(AuthenticationStoreActions.authenticationSucceeded, state => ({
    ...state,
    isAuthenticated: true,
    isAuthenticating: false,
  })),
  on(AuthenticationStoreActions.deleteUserSignedAgreementFailed, state => ({
    ...state,
    isDeletingUserSignedAgreement: null,
  })),
  on(
    AuthenticationStoreActions.deleteUserSignedAgreementSucceeded,
    (state, { data }) => {
      const newUserAgreements = state.user.userAgreements.filter(
        agreement => agreement.agreementId !== data.agreementId,
      );
      const updatedUser = { ...state.user, userAgreements: newUserAgreements };
      return {
        ...state,
        isDeletingUserSignedAgreement: null,
        user: updatedUser,
      };
    },
  ),
  on(AuthenticationStoreActions.loginFailed, state => ({
    ...state,
    isLoggedIn: false,
    isLoggingIn: false,
  })),
  on(AuthenticationStoreActions.loginSucceeded, state => ({
    ...state,
    isLoggedIn: true,
    isLoggingIn: false,
  })),
  on(AuthenticationStoreActions.logoutFailed, state => ({
    ...state,
    isLoggingOut: false,
  })),
  on(AuthenticationStoreActions.logoutSucceeded, state => ({
    ...state,
    isLoggedIn: false,
    isLoggingOut: false,
  })),
  on(AuthenticationStoreActions.requestLogout, state => ({
    ...state,
    isAuthenticated: false,
    isAuthenticating: false,
    isLoggingIn: false,
    token: null,
    unsignedAgreements: [],
    user: null,
  })),
  on(
    AuthenticationStoreActions.requestDeleteUserSignedAgreement,
    (state, { data }) => ({
      ...state,
      isDeletingUserSignedAgreement: {
        agreementId: data.agreementId,
        userId: data.userId,
      },
    }),
  ),
  on(AuthenticationStoreActions.requestLogin, state => ({
    ...state,
    isLoggingIn: true,
  })),
  on(AuthenticationStoreActions.requestLogout, state => ({
    ...state,
    isLoggingOut: true,
  })),
  on(AuthenticationStoreActions.requestSignAgreement, state => ({
    ...state,
    isAcceptingAgreement: true,
  })),
  on(AuthenticationStoreActions.setToken, (state, { token }) => ({
    ...state,
    token,
  })),
  on(AuthenticationStoreActions.setUser, (state, { user }) => ({
    ...state,
    user,
  })),
  on(AuthenticationStoreActions.signAgreementFailed, state => ({
    ...state,
    isAcceptingAgreement: false,
  })),
  on(AuthenticationStoreActions.signAgreementSucceeded, (state, { data }) => {
    // @AP-121 : Checking for state.unsignedAgreements as array to ensure users
    // accessing the application for the first time after a state structure
    // change do not encounter a JS error here.
    // @TODO Can be simplified once the application is updated to reset state
    // structure for each new application version.
    const newUnsignedAgreements = Array.isArray(state.unsignedAgreements)
      ? state.unsignedAgreements.filter(
          agreement => agreement.agreementId !== data.agreementId,
        )
      : [];
    const newUser = { ...state.user };
    // @AP-121 : Checking for state.user.userAgreements as array to ensure
    // users with no signed user agreements do not cause a JS error when
    // signing their first agreement.
    // @TODO Can be simplified once the database is converted to SQL and we can
    // be certain that `userAgreements` attribute is present even when empty.
    newUser.userAgreements = Array.isArray(newUser.userAgreements)
      ? [...newUser.userAgreements, data]
      : [data];
    return {
      ...state,
      isAcceptingAgreement: false,
      unsignedAgreements: newUnsignedAgreements,
      user: newUser,
    };
  }),
  on(
    AuthenticationStoreActions.unsignedAgreementsCheckSucceeded,
    (state, { data }) => ({
      ...state,
      unsignedAgreements: data,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return authenticationReducer(state, action);
}
