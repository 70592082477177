import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // Temporarily replacing snackbar alerts with standard browser
          // alerts to be able to remove Material. Will be replaced with
          // different alert UI when available.
          //window.alert(err.error && err.error.message ? err.error.message : err.message);

          if (err.status === 0 || err.status === 401) {
            // this.store.dispatch(new LogoutUser());
            //window.alert('You are not allowed to do that.');
          }
        }

        return throwError(err);
      }),
    );
  }
}
