export enum Permissions {
  AccessContentOffline = 'access_content_offline',
  AccessDownloads = 'access_downloads',
  AccessHelp = 'access_help',
  AdministerAssociatedInstitutions = 'administer_associated_institutions',
  AdministerAllInstitutions = 'administer_all_institutions',
  CreateInstitutions = 'create_institutions',
  AccessScenarios = 'access_scenarios',
  AccessScenarioRevisionReleaseManagement = 'access_scenario_revision_release_management',
  AdministerAllScenarios = 'administer_all_scenarios',
  AdministerScenarios = 'administer_scenarios',
  CreateScenarios = 'create_scenarios',
  AccessScenarioPackages = 'access_scenario_packages',
  AdministerAllScenarioPackages = 'administer_all_scenario_packages',
  AdministerScenarioPackages = 'administer_scenario_packages',
  CreateScenarioPackages = 'create_scenario_packages',
  DeleteScenarioPackages = 'delete_scenario_packages',
  DeleteAnyScenarioPackage = 'delete_any_scenario_package',
  AdministerAllUsers = 'administer_all_users',
  AdministerUsers = 'administer_users',
  CreateUsers = 'create_users',
}
