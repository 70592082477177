import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthenticationService } from '@simx/modules/authentication';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _token: string;

  constructor(private readonly _authenticationService: AuthenticationService) {
    this._authenticationService.getToken$().subscribe((token: string) => {
      if (!token) {
        return;
      }
      this._token = token;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('Authorization') || !this._token) {
      return next.handle(request);
    }

    const newRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this._token}`,
      },
    });

    return next.handle(newRequest);
  }
}
