import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInFromTopAnimation,
  fadeOutToTopAnimation,
} from '@simx/shared/animations';
import { Taxonomies } from '@simx/modules/taxonomy/constants';
import { TaxonomyItem } from '@simx/shared/models';
import { TaxonomyService } from '@simx/modules/taxonomy/services';

@Component({
  selector: 'taxonomy-tag-list',
  templateUrl: './taxonomy-tag-list.component.html',
  styleUrls: ['./taxonomy-tag-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  animations: [
    trigger('fadeInFromTopOutToTopAnimation', [
      transition(':enter', [
        useAnimation(fadeInFromTopAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToTopAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class TaxonomyTagListComponent implements OnChanges, OnDestroy, OnInit {
  @Input() taxonomy: Taxonomies;
  taxonomyItems: Array<TaxonomyItem> = [];
  taxonomyItemsAvailableToAdd: Array<TaxonomyItem> = [];

  @Input() tags: Array<string> = [];
  @Input() order: Array<string>;

  @Input() allowRemove: boolean = false;
  @Input() allowAdd: boolean = false;

  @Output('remove') removeEvent: EventEmitter<string> =
    new EventEmitter<string>();
  @Output('add') addEvent: EventEmitter<string> = new EventEmitter<string>();

  addMenuDisplayed: boolean = false;

  hasLoadedTaxonomy: boolean = false;

  windowClickListener: () => void;

  constructor(
    private renderer: Renderer2,
    private taxonomyService: TaxonomyService,
  ) {}

  ngOnInit() {
    this.taxonomyService
      .getTaxonomy(this.taxonomy)
      .then((taxonomy: Array<TaxonomyItem>) => {
        this.taxonomyItems = taxonomy;
        this.hasLoadedTaxonomy = true;

        this.updateTaxonomyItemsAvailableToAdd();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tags) {
      if (this.hasLoadedTaxonomy) {
        this.updateTaxonomyItemsAvailableToAdd();
      }

      if (this.order) {
        this.tags = changes.tags.currentValue.sort(
          (a, b) => this.order.indexOf(a) - this.order.indexOf(b),
        );
      }
    }
  }

  updateTaxonomyItemsAvailableToAdd() {
    this.taxonomyItemsAvailableToAdd = this.taxonomyItems.filter(
      item => !this.tags.includes(item.term),
    );

    if (this.order) {
      this.taxonomyItemsAvailableToAdd.sort(
        (a, b) => this.order.indexOf(a.term) - this.order.indexOf(b.term),
      );
    }
  }

  toggleDisplayAddMenu() {
    const newState = !this.addMenuDisplayed;
    this.setAddMenuDisplayed(newState);
  }

  setAddMenuDisplayed(state: boolean) {
    this.addMenuDisplayed = state;

    if (state) {
      this.windowClickListener = this.renderer.listen(
        'window',
        'click',
        (event: Event) => {
          this.setAddMenuDisplayed(false);
        },
      );
    } else {
      if (this.windowClickListener) {
        this.windowClickListener();
      }
    }
  }

  handleTagRemoveAction(tag: string) {
    this.removeEvent.emit(tag);
  }

  handleAddMenuToggle(event: Event) {
    event.stopPropagation();

    this.toggleDisplayAddMenu();
  }

  handleAddMenuListItemClick(taxonomyTerm: string) {
    this.addEvent.emit(taxonomyTerm);
    this.setAddMenuDisplayed(false);
  }

  ngOnDestroy() {
    if (this.windowClickListener) {
      this.windowClickListener();
    }
  }
}
