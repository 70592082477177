import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InstitutionStoreSelectors, InstitutionStoreActions } from '../store';
import { Observable } from 'rxjs';
import {
  Institution,
  InstitutionScenarioPackage,
  ScenarioPackage,
} from '@simx/shared/models';
import { Permissions } from '@simx/shared/constants';
import {
  InstitutionDataService,
  InstitutionScenarioPackageDataService,
  ScenarioPackageDataService,
} from '@simx/shared/services';
import { AuthenticationService } from '@simx/modules/authentication/services';

@Injectable({ providedIn: 'root' })
export class InstitutionAdministrationService {
  scenarioPackageListStore: Array<ScenarioPackage> = null;
  scenarioPackageWithItemsStore: any = {};

  institutionExpirationWindowMax: number = 30;
  urgentInstitutionExpirationWindowMax: number = 15;

  constructor(
    private store: Store,
    private institutionDataService: InstitutionDataService,
    private institutionScenarioPackageDataService: InstitutionScenarioPackageDataService,
    private scenarioPackageDataService: ScenarioPackageDataService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.getIsLoggedIn$().subscribe((state: boolean) => {
      if (!state) {
        this.resetLocalStores();
      }
    });
  }

  authenticatedUserCan(permission: Permissions): boolean {
    return this.authenticationService.isAllowed(permission);
  }

  resetLocalStores() {
    this.scenarioPackageListStore = null;
    this.scenarioPackageWithItemsStore = {};
  }

  getInstitutionList$(): Observable<Array<Institution>> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectInstitutionList)
        .subscribe((institutions: Array<Institution>) => {
          observer.next(institutions);
        });
      this.store.dispatch(InstitutionStoreActions.checkInstitutionListLoaded());
    });
  }

  getIsLoadingInstitutionList$(): Observable<boolean> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectIsLoadingInstitutionList)
        .subscribe((state: boolean) => {
          observer.next(state);
        });
    });
  }

  getHasLoadedInstitutionList$(): Observable<boolean> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectHasLoadedInstitutionList)
        .subscribe((state: boolean) => {
          observer.next(state);
        });
    });
  }

  getLoadInstitutionListError$(): Observable<string> {
    return new Observable((observer: any) => {
      this.store
        .select(InstitutionStoreSelectors.selectLoadInstitutionListError)
        .subscribe((error: string) => {
          observer.next(error);
        });
    });
  }

  clearInstitutionDataAndLoadInstitutionList() {
    this.store.dispatch(
      InstitutionStoreActions.clearInstitutionStoreAndLoadInstitutionList(),
    );
  }

  getInstitution(institutionId: string): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .getInstitution(institutionId)
        .then((institution: Institution) => {
          resolve(institution);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      this.institutionScenarioPackageDataService
        .getInstitutionScenarioPackageItemsForInstitution(institutionId, {
          scenarioPackage: true,
          scenarioPackageItems: true,
          scenarioPackageItemsScenario: true,
        })
        .then(
          (institutionScenarioPackages: Array<InstitutionScenarioPackage>) => {
            resolve(institutionScenarioPackages);
          },
        )
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  setInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
    institutionScenarioPackageItems: Array<InstitutionScenarioPackage>,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      this.institutionScenarioPackageDataService
        .setInstitutionScenarioPackageItemsForInstitution(
          institutionId,
          institutionScenarioPackageItems,
          {
            scenarioPackage: true,
            scenarioPackageItems: true,
            scenarioPackageItemsScenario: true,
          },
        )
        .then(
          (institutionScenarioPackages: Array<InstitutionScenarioPackage>) => {
            resolve(institutionScenarioPackages);
          },
        )
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getScenarioPackageWithItems(
    scenarioPackageId: string,
  ): Promise<ScenarioPackage> {
    return new Promise((resolve, reject) => {
      if (
        this.scenarioPackageWithItemsStore.hasOwnProperty(scenarioPackageId)
      ) {
        resolve(this.scenarioPackageWithItemsStore[scenarioPackageId]);
      } else {
        const includes = {
          items: true,
          itemsScenario: true,
        };

        this.scenarioPackageDataService
          .getScenarioPackage(scenarioPackageId, includes)
          .then((scenarioPackage: ScenarioPackage) => {
            this.scenarioPackageWithItemsStore[scenarioPackageId] =
              scenarioPackage;

            resolve(scenarioPackage);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  getAuthenticatedUserAllowedScenarioPackages(): Promise<
    Array<ScenarioPackage>
  > {
    return new Promise((resolve, reject) => {
      if (this.scenarioPackageListStore) {
        resolve([...this.scenarioPackageListStore]);
      } else {
        this.scenarioPackageDataService
          .getScenarioPackages()
          .then((scenarioPackages: Array<ScenarioPackage>) => {
            this.scenarioPackageListStore = scenarioPackages;

            resolve([...this.scenarioPackageListStore]);
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  createInstitution(institution: Institution): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .createInstitution(institution)
        .then((institution: Institution) => {
          this.store.dispatch(
            InstitutionStoreActions.addInstitutionToInstitutionList({
              institution,
            }),
          );

          resolve(institution);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  updateInstitution(institution: Institution): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .updateInstitution(institution)
        .then((institution: Institution) => {
          this.store.dispatch(
            InstitutionStoreActions.updateInstitutionInInstitutionList({
              institution,
            }),
          );

          resolve(institution);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  reactivateInstitution(institutionId: string): Promise<Institution> {
    return new Promise((resolve, reject) => {
      this.institutionDataService
        .reactivateInstitution(institutionId)
        .then((response: Institution) => {
          this.store.dispatch(
            InstitutionStoreActions.updateInstitutionInInstitutionList({
              institution: response,
            }),
          );

          resolve(response);
        })
        .catch((error: string) => {
          reject(error);
        });
    });
  }

  reactivateInstitutions(
    institutionIds: Array<string>,
  ): Promise<Array<Institution>> {
    return new Promise((resolve, reject) => {
      const reactivateTasks = institutionIds.map((institutionId: string) =>
        this.reactivateInstitution(institutionId),
      );
      Promise.all(reactivateTasks)
        .then((responses: Array<Institution>) => {
          resolve(responses);
        })
        .catch((error: string) => {
          reject(error);
        });
    });
  }
}
