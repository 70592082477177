import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AccordionListItemViewStates,
  IconButtonIconTypes,
} from '../../../../constants';

@Component({
  selector: 'accordion-list-item',
  templateUrl: './accordion-list-item.component.html',
  styleUrls: ['./accordion-list-item.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AccordionListItemComponent {
  get cssClasses() {
    const classes = [];
    classes.push(this.currentViewState);
    if (this.headerOnly) {
      classes.push('simx-header-only');
    }
    return classes;
  }

  @Input() headerOnly: boolean = false;
  @Input() allowRemove: boolean = false;

  @Output('remove') removeEvent: EventEmitter<void> = new EventEmitter<void>();

  currentViewState: AccordionListItemViewStates =
    AccordionListItemViewStates.Expanded;

  iconButtonIconTypes = IconButtonIconTypes;

  handleChangeViewStateEvent(viewState: AccordionListItemViewStates) {
    this.setViewState(viewState);
  }

  handleRemoveAction() {
    this.removeFromList();
  }

  setViewState(viewState: AccordionListItemViewStates) {
    this.currentViewState = viewState;
  }

  removeFromList() {
    this.removeEvent.emit();
  }
}
