import { NgModule } from '@angular/core';
import { TaxonomyStoreModule } from './store';
import { TaxonomyTermLabelPipe } from './pipes';

@NgModule({
  declarations: [TaxonomyTermLabelPipe],
  imports: [TaxonomyStoreModule],
  exports: [TaxonomyTermLabelPipe],
})
export class TaxonomyModule {}
