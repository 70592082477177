import { Component, Input } from '@angular/core';

@Component({
  selector: 'content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class ContentContainerComponent {
  @Input() scroll: boolean = true;
  @Input() gutter: boolean = true;

  get classes() {
    return [
      this.scroll ? 'simx-scroll-content' : '',
      this.gutter ? 'simx-gutter' : '',
    ]
      .join(' ')
      .trim();
  }
}
