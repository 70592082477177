export class ScenarioRelease {
  active?: boolean;
  author?: string;
  createdAt?: string;
  createdByUserId?: string;
  dataFile?: string;
  dataFileKey?: string;
  description?: string;
  name?: string;
  scenarioId?: string;
  scenarioReleaseId?: string;
  scenarioRevisionId?: string;
  updatedAt?: string;
  version?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('active')) {
        this.active = data.active;
      }
      if (data.hasOwnProperty('author')) {
        this.author = data.author;
      }
      if (data.hasOwnProperty('createdAt')) {
        this.createdAt = data.createdAt;
      }
      if (data.hasOwnProperty('createdByUserId')) {
        this.createdByUserId = data.createdByUserId;
      }
      if (data.hasOwnProperty('dataFile')) {
        this.dataFile = data.dataFile;
      }
      if (data.hasOwnProperty('dataFileKey')) {
        this.dataFileKey = data.dataFileKey;
      }
      if (data.hasOwnProperty('description')) {
        this.description = data.description;
      }
      if (data.hasOwnProperty('name')) {
        this.name = data.name;
      }
      if (data.hasOwnProperty('scenarioId')) {
        this.scenarioId = data.scenarioId;
      }
      if (data.hasOwnProperty('scenarioReleaseId')) {
        this.scenarioReleaseId = data.scenarioReleaseId;
      }
      if (data.hasOwnProperty('scenarioRevisionId')) {
        this.scenarioRevisionId = data.scenarioRevisionId;
      }
      if (data.hasOwnProperty('updatedAt')) {
        this.updatedAt = data.updatedAt;
      }
      if (data.hasOwnProperty('version')) {
        this.version = data.version;
      }
    }
  }
}
