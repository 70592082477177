import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Institution } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class InstitutionDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getInstitutions(): Promise<Array<Institution>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionsUrl();

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const institutions = response.map(item => new Institution(item));

          resolve(institutions);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getInstitution(
    institutionId: string,
    returnValueOptions?: any,
  ): Promise<Institution> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionUrl(
        institutionId,
        returnValueOptions,
      );

      this.http
        .get(url)
        .toPromise()
        .then((response: any) => {
          const institution = new Institution(response);

          resolve(institution);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  createInstitution(institution: Institution): Promise<Institution> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionUrl();
      const body = institution;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const institution = new Institution(response);

          resolve(institution);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  updateInstitution(institution: Institution): Promise<Institution> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionUrl();
      const body = institution;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: any) => {
          const institution = new Institution(response);

          resolve(institution);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  reactivateInstitution(institutionId: string): Promise<Institution> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.reactivateInstitutionUrl();
      const body = { institutionId };

      this.http
        .post(url, body)
        .toPromise()
        .then((response: Institution) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
