export enum Taxonomies {
  InstitutionTag = 'InstitutionTag',
  InstitutionType = 'InstitutionType',
  UserTag = 'UserTag',
  ScenarioTag = 'ScenarioTag',
  ScenarioPackageTag = 'ScenarioPackageTag',
  ReleaseTag = 'ReleaseTag',
  UserPermission = 'UserPermission',
  InstitutionPermission = 'InstitutionPermission',
}
