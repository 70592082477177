import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './institution-store.state';
import { Institution } from '@simx/shared/models';

const getEntityList = (state: State): Array<Institution> => state.entityList;
const getIsLoadingEntityList = (state: State): boolean =>
  state.isLoadingEntityList;
const getHasLoadedEntityList = (state: State): boolean =>
  state.hasLoadedEntityList;
const getLoadEntityListError = (state: State): string =>
  state.loadEntityListError;

export const selectInstitutionState =
  createFeatureSelector<State>('institution');
export const selectInstitutionList = createSelector(
  selectInstitutionState,
  getEntityList,
);
export const selectIsLoadingInstitutionList = createSelector(
  selectInstitutionState,
  getIsLoadingEntityList,
);
export const selectHasLoadedInstitutionList = createSelector(
  selectInstitutionState,
  getHasLoadedEntityList,
);
export const selectLoadInstitutionListError = createSelector(
  selectInstitutionState,
  getLoadEntityListError,
);
