import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { ScenarioRelease } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioReleaseDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getScenarioReleasesForScenario(
    scenarioId: string,
  ): Promise<Array<ScenarioRelease>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioReleasesUrl(scenarioId);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const scenarioReleases = response.map(
            item => new ScenarioRelease(item),
          );

          resolve(scenarioReleases);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  createScenarioRelease(
    scenarioRelease: ScenarioRelease,
  ): Promise<ScenarioRelease> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioReleaseUrl();
      const body = scenarioRelease;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const scenarioRelease = new ScenarioRelease(response);

          resolve(scenarioRelease);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  deleteScenarioRelease(scenarioReleaseId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = this.dataService.scenarioReleaseUrl(scenarioReleaseId);

      this.http
        .delete(url)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getScenarioReleaseDataFile(scenarioReleaseId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioReleaseFileUrl(scenarioReleaseId);

      this.http
        .get(url, { responseType: 'text' })
        .toPromise()
        .then((file: string) => {
          resolve(file);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
