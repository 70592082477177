import { Component, Input } from '@angular/core';
import { LoadingIndicatorStyles } from '../../../constants';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class LoadingIndicatorComponent {
  @Input() size: number;
  @Input() style: LoadingIndicatorStyles;

  get borderWidth() {
    return this.size * 0.15;
  }

  get classes() {
    const classNames = [];
    classNames.push(this.style);
    return classNames.join(' ');
  }
}
