export class TaxonomyItem {
  label?: string;
  taxonomyName?: string;
  term?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('label')) {
        this.label = data.label;
      }
      if (data.hasOwnProperty('taxonomyName')) {
        this.taxonomyName = data.taxonomyName;
      }
      if (data.hasOwnProperty('term')) {
        this.term = data.term;
      }
    }
  }
}
