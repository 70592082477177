export class Institution {
  active?: boolean;
  code?: number;
  createdAt?: string;
  expiration?: string;
  institutionId?: string;
  name?: string;
  simulationOptions?: any;
  tags?: Array<string>;
  type?: string;
  updatedAt?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('active')) {
        this.active = data.active;
      }
      if (data.hasOwnProperty('code')) {
        this.code = data.code;
      }
      if (data.hasOwnProperty('createdAt')) {
        this.createdAt = data.createdAt;
      }
      if (data.hasOwnProperty('expiration')) {
        this.expiration = data.expiration;
      }
      if (data.hasOwnProperty('institutionId')) {
        this.institutionId = data.institutionId;
      }
      if (data.hasOwnProperty('name')) {
        this.name = data.name;
      }
      if (data.hasOwnProperty('simulationOptions')) {
        this.simulationOptions = data.simulationOptions;
      }
      if (data.hasOwnProperty('tags')) {
        this.tags = data.tags;
      }
      if (data.hasOwnProperty('type')) {
        this.type = data.type;
      }
      if (data.hasOwnProperty('updatedAt')) {
        this.updatedAt = data.updatedAt;
      }
    }
  }
}
