import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './taxonomy-store.state';

const getIsLoadingTaxonomies = (state: State): Array<string> =>
  state.isLoadingTaxonomies;
const getHasLoadedTaxonomies = (state: State): Array<string> =>
  state.hasLoadedTaxonomies;
const getTaxonomies = (state: State): any => state.taxonomies;

export const selectTaxonomyState = createFeatureSelector<State>('taxonomy');
export const selectIsLoadingTaxonomies = createSelector(
  selectTaxonomyState,
  getIsLoadingTaxonomies,
);
export const selectHasLoadedTaxonomies = createSelector(
  selectTaxonomyState,
  getHasLoadedTaxonomies,
);
export const selectTaxonomies = createSelector(
  selectTaxonomyState,
  getTaxonomies,
);
export const selectTaxonomy = createSelector(
  selectTaxonomies,
  (taxonomies, { taxonomyName }) =>
    taxonomies.hasOwnProperty(taxonomyName)
      ? taxonomies[taxonomyName]
      : undefined,
);
export const selectTaxonomyItem = createSelector(
  selectTaxonomies,
  (taxonomies, { term, taxonomyName }) =>
    taxonomies.hasOwnProperty(taxonomyName)
      ? taxonomies[taxonomyName].find(
          taxonomyItem => taxonomyItem.term === term,
        )
      : undefined,
);
