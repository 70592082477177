export interface State {
  isLoadingTaxonomies: Array<string>;
  hasLoadedTaxonomies: Array<string>;
  taxonomies: any;
}

export const initialState: State = {
  isLoadingTaxonomies: [],
  hasLoadedTaxonomies: [],
  taxonomies: {},
};
