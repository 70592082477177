import { AppHeaderComponent } from './app-header';
import { AppSidebarComponent } from './app-sidebar';
import { AppViewComponent } from './app-view';
import { SplashViewComponent } from './splash-view';

export const allComponents: any[] = [
  AppHeaderComponent,
  AppSidebarComponent,
  AppViewComponent,
  SplashViewComponent,
];
export const publicComponents: any[] = [AppViewComponent];

export * from './app-header';
export * from './app-sidebar';
export * from './app-view';
export * from './splash-view';
