import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { InstitutionScenarioPackage } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class InstitutionScenarioPackageDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  createInstitutionScenarioPackage(
    institutionScenarioPackage: InstitutionScenarioPackage,
  ): Promise<InstitutionScenarioPackage> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionScenarioPackageUrl();
      const body = institutionScenarioPackage;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const institutionScenarioPackage = new InstitutionScenarioPackage(
            response,
          );

          resolve(institutionScenarioPackage);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
    includes?: any,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionScenarioPackagesUrl(
        institutionId,
        includes,
      );

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const institutionScenarioPackages = response.map(
            item => new InstitutionScenarioPackage(item),
          );

          resolve(institutionScenarioPackages);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getInstitutionScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    includes?: any,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageInstitutionsUrl(
        scenarioPackageId,
        includes,
      );

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const institutionScenarioPackages = response.map(
            item => new InstitutionScenarioPackage(item),
          );

          resolve(institutionScenarioPackages);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  setInstitutionScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    institutionScenarioPackageItems: Array<InstitutionScenarioPackage>,
    includes?: any,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageInstitutionsUrl(
        scenarioPackageId,
        includes,
      );
      const body = institutionScenarioPackageItems;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: Array<any>) => {
          const institutionScenarioPackageItems = response.map(
            item => new InstitutionScenarioPackage(item),
          );

          resolve(institutionScenarioPackageItems);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  setInstitutionScenarioPackageItemsForInstitution(
    institutionId: string,
    institutionScenarioPackageItems: Array<InstitutionScenarioPackage>,
    includes?: any,
  ): Promise<Array<InstitutionScenarioPackage>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.institutionScenarioPackagesUrl(
        institutionId,
        includes,
      );
      const body = institutionScenarioPackageItems;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: Array<any>) => {
          const institutionScenarioPackageItems = response.map(
            item => new InstitutionScenarioPackage(item),
          );

          resolve(institutionScenarioPackageItems);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
