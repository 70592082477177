import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as AuthenticationStoreActions from './authentication-store.actions';
import { AgreementDataService } from '@simx/shared/services';
import { AuthenticationService } from '../services';
import { AuthenticationStoreSelectors } from '.';

@Injectable()
export class AuthenticationStoreEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _agreementDataService: AgreementDataService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _store: Store,
  ) {}

  authenticateUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.RequestLogin),
      switchMap(({ data }) =>
        this._authenticationService.authenticateUser(data),
      ),
    ),
  );

  setUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.AuthenticationSucceeded),
      map((action: any) => action.data),
      map(({ user }) => AuthenticationStoreActions.setUser({ user })),
    ),
  );

  setToken$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.AuthenticationSucceeded),
      map((action: any) => action.data),
      map(({ token }) => AuthenticationStoreActions.setToken({ token })),
    ),
  );

  checkForUnsignedAgreements$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.AuthenticationSucceeded),
      switchMap(() => this._authenticationService.checkForUnsignedAgreements()),
    ),
  );

  processUnsignedAgreements$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          AuthenticationStoreActions.ActionType
            .UnsignedAgreementsCheckSucceeded,
        ),
        tap(({ data }) => {
          this._authenticationService.processUnsignedAgreements(data);
        }),
      ),
    { dispatch: false },
  );

  loopUnsignedAgreements$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthenticationStoreActions.ActionType.SignAgreementSucceeded),
        withLatestFrom(
          this._store.select(
            AuthenticationStoreSelectors.selectUnsignedAgreements,
          ),
        ),
        tap(([_, unsignedAgreements]) => {
          this._authenticationService.processUnsignedAgreements(
            unsignedAgreements,
          );
        }),
      ),
    { dispatch: false },
  );

  handleFailedLogin$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        AuthenticationStoreActions.ActionType.AuthenticationFailed,
        AuthenticationStoreActions.ActionType.UnsignedAgreementsCheckFailed,
        AuthenticationStoreActions.ActionType.SignAgreementFailed,
      ),
      map(({ error }) => AuthenticationStoreActions.loginFailed({ error })),
    ),
  );

  completeLogin$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthenticationStoreActions.ActionType.LoginSucceeded),
        tap(() => {
          this._authenticationService.completeLogin();
        }),
      ),
    { dispatch: false },
  );

  logoutUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.RequestLogout),
      map(() => AuthenticationStoreActions.logoutSucceeded()),
    ),
  );

  completeLogout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(AuthenticationStoreActions.ActionType.LogoutSucceeded),
        tap(() => {
          this._authenticationService.completeLogout();
        }),
      ),
    { dispatch: false },
  );

  executeSignAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.RequestSignAgreement),
      withLatestFrom(
        this._store.select(AuthenticationStoreSelectors.selectUserId),
      ),
      switchMap(([{ agreementId }, userId]) =>
        this._authenticationService.signAgreement(userId, agreementId),
      ),
    ),
  );

  executeDeleteUserSignedAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        AuthenticationStoreActions.ActionType.RequestDeleteUserSignedAgreement,
      ),
      map((action: any) => action.data),
      switchMap(({ userId, agreementId }) =>
        this._agreementDataService.deleteUserSignedAgreement(
          userId,
          agreementId,
        ),
      ),
    ),
  );
}
