import { Pipe, PipeTransform } from '@angular/core';
import { TaxonomyService } from '../services';
import { Taxonomies } from '../constants';
import { TaxonomyItem } from '@simx/shared/models';

@Pipe({ name: 'taxonomyTermLabel' })
export class TaxonomyTermLabelPipe implements PipeTransform {
  constructor(private taxonomyService: TaxonomyService) {}

  transform(term: string, taxonomyName: Taxonomies): Promise<string> {
    return new Promise((resolve, reject) => {
      this.taxonomyService
        .getTaxonomyItem(term, taxonomyName)
        .then((taxonomyItem: TaxonomyItem) => {
          resolve(taxonomyItem.label);
        });
    });
  }
}
