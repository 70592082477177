import { Action, createReducer, on } from '@ngrx/store';
import { State, initialState } from './institution-store.state';
import * as InstitutionStoreActions from './institution-store.actions';

const institutionReducer = createReducer(
  initialState,
  on(InstitutionStoreActions.loadInstitutionListRequested, state => ({
    ...state,
    isLoadingEntityList: true,
    loadEntityListError: null,
  })),
  on(InstitutionStoreActions.loadInstitutionListSucceeded, state => ({
    ...state,
    isLoadingEntityList: false,
    loadEntityListError: null,
  })),
  on(InstitutionStoreActions.loadInstitutionListFailed, (state, { error }) => ({
    ...state,
    isLoadingEntityList: false,
    loadEntityListError: error,
  })),
  on(
    InstitutionStoreActions.loadInstitutionList,
    (state, { institutions }) => ({
      ...state,
      entityList: institutions,
      hasLoadedEntityList: true,
    }),
  ),
  on(
    InstitutionStoreActions.addInstitutionToInstitutionList,
    (state, { institution }) => ({
      ...state,
      entityList: [...state.entityList, institution],
    }),
  ),
  on(
    InstitutionStoreActions.updateInstitutionInInstitutionList,
    (state, { institution }) => ({
      ...state,
      entityList: [
        ...state.entityList.filter(
          existingInstitution =>
            existingInstitution.institutionId !== institution.institutionId,
        ),
        institution,
      ],
    }),
  ),
  on(
    InstitutionStoreActions.removeInstitutionFromInstitutionList,
    (state, { institutionId }) => ({
      ...state,
      entityList: state.entityList.filter(
        existingInstitution =>
          existingInstitution.institutionId !== institutionId,
      ),
    }),
  ),
  on(
    InstitutionStoreActions.clearInstitutionStoreAndLoadInstitutionList,
    state => ({ ...initialState }),
  ),
  on(InstitutionStoreActions.clearInstitutionStore, state => ({
    ...initialState,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return institutionReducer(state, action);
}
