import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CheckListSelectItem } from '../../../models';

@Component({
  selector: 'check-list-select',
  templateUrl: './check-list-select.component.html',
  styleUrls: ['./check-list-select.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckListSelectComponent,
    },
  ],
})
export class CheckListSelectComponent implements ControlValueAccessor {
  @Input() id: string;
  @Input() items: Array<CheckListSelectItem>;

  checkedItems: Array<string> = [];

  onChange = x => {};
  onTouched = () => {};
  touched: boolean = false;
  disabled: boolean = false;

  getCheckItemId(item: CheckListSelectItem): string {
    return `${this.id}--${item.value}`;
  }

  handleItemCheckedValueChange(clickedItemValue: string, event: any) {
    this.markAsTouched();

    if (this.disabled) {
      return;
    }

    const checked = event.target.checked;
    if (checked) {
      this.checkedItems = [...this.checkedItems, clickedItemValue];
    } else {
      this.checkedItems = this.checkedItems.filter(
        itemValue => itemValue !== clickedItemValue,
      );
    }

    this.onChange(this.checkedItems);
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  writeValue(value: Array<string>) {
    this.checkedItems = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabledState: boolean) {
    this.disabled = disabledState;
  }
}
