import {
  ContentContainerComponent,
  ViewContainerComponent,
} from './containers';
import {
  AccordionListCollapseExpandToggleComponent,
  AccordionListComponent,
  AccordionListItemBodyComponent,
  AccordionListItemComponent,
  AccordionListItemHeaderComponent,
  ActionButtonComponent,
  CheckListSelectComponent,
  CheckSelectListComponent,
  IconButtonComponent,
  PermissionsListComponent,
  ReleaseTagListComponent,
  SelectListComponent,
  SelectListRowComponent,
  TableListComponent,
  TableListItemComponent,
  TaxonomyTagListComponent,
} from './controls';
import {
  DropDownMenuComponent,
  ErrorMessageComponent,
  InteractionPanelComponent,
  ListFilterAttributeClearComponent,
  ListFilterPanelComponent,
  LoadingIndicatorComponent,
  TagComponent,
  UserMessageComponent,
} from './elements';
import {
  EntityDetailAttributeComponent,
  EntityDetailComponent,
  EntityDetailDividerComponent,
  EntityDetailHeaderComponent,
} from './entity-detail';
import { TwoPanelLayoutComponent } from './layout';

export const allComponents: any[] = [
  AccordionListCollapseExpandToggleComponent,
  AccordionListComponent,
  AccordionListItemBodyComponent,
  AccordionListItemComponent,
  AccordionListItemHeaderComponent,
  ActionButtonComponent,
  CheckListSelectComponent,
  CheckSelectListComponent,
  ContentContainerComponent,
  DropDownMenuComponent,
  EntityDetailAttributeComponent,
  EntityDetailComponent,
  EntityDetailDividerComponent,
  EntityDetailHeaderComponent,
  ErrorMessageComponent,
  IconButtonComponent,
  InteractionPanelComponent,
  ListFilterAttributeClearComponent,
  ListFilterPanelComponent,
  LoadingIndicatorComponent,
  PermissionsListComponent,
  ReleaseTagListComponent,
  SelectListComponent,
  SelectListRowComponent,
  TableListComponent,
  TableListItemComponent,
  TagComponent,
  TaxonomyTagListComponent,
  TwoPanelLayoutComponent,
  UserMessageComponent,
  ViewContainerComponent,
];
export const publicComponents: any[] = [
  AccordionListCollapseExpandToggleComponent,
  AccordionListComponent,
  AccordionListItemBodyComponent,
  AccordionListItemComponent,
  AccordionListItemHeaderComponent,
  ActionButtonComponent,
  CheckListSelectComponent,
  CheckSelectListComponent,
  ContentContainerComponent,
  DropDownMenuComponent,
  EntityDetailAttributeComponent,
  EntityDetailComponent,
  EntityDetailDividerComponent,
  EntityDetailHeaderComponent,
  ErrorMessageComponent,
  IconButtonComponent,
  InteractionPanelComponent,
  ListFilterAttributeClearComponent,
  ListFilterPanelComponent,
  LoadingIndicatorComponent,
  PermissionsListComponent,
  ReleaseTagListComponent,
  SelectListComponent,
  TableListComponent,
  TableListItemComponent,
  TagComponent,
  TaxonomyTagListComponent,
  TwoPanelLayoutComponent,
  UserMessageComponent,
  ViewContainerComponent,
];

export * from './containers';
export * from './controls';
export * from './elements';
export * from './entity-detail';
export * from './layout';
