import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIElementsModule } from '@simx/modules/ui-elements';
import {
  DatePipe,
  InstitutionExpirationWindowPipe,
  TimestampPipe,
  TitleCasePipe,
} from '../pipes';

@NgModule({
  declarations: [
    DatePipe,
    InstitutionExpirationWindowPipe,
    TimestampPipe,
    TitleCasePipe,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UIElementsModule],
  exports: [
    CommonModule,
    DatePipe,
    FormsModule,
    InstitutionExpirationWindowPipe,
    ReactiveFormsModule,
    TimestampPipe,
    TitleCasePipe,
    UIElementsModule,
  ],
})
export class SharedModule {}
