import { ScenarioPackageItem } from './scenario-package-item.model';

export class ScenarioPackage {
  createdAt?: string;
  items?: Array<ScenarioPackageItem>;
  name?: string;
  scenarioPackageId?: string;
  tags?: Array<string>;
  updatedAt?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('createdAt')) {
        this.createdAt = data.createdAt;
      }
      if (data.hasOwnProperty('items')) {
        this.items = data.items.map(item => new ScenarioPackageItem(item));
      }
      if (data.hasOwnProperty('name')) {
        this.name = data.name;
      }
      if (data.hasOwnProperty('scenarioPackageId')) {
        this.scenarioPackageId = data.scenarioPackageId;
      }
      if (data.hasOwnProperty('tags')) {
        this.tags = data.tags;
      }
      if (data.hasOwnProperty('updatedAt')) {
        this.updatedAt = data.updatedAt;
      }
    }
  }
}
