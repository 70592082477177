import { Injectable } from '@angular/core';
import { environment } from '@simx/env';

@Injectable({ providedIn: 'root' })
export class DataService {
  serverUrl: string;

  constructor() {
    this.serverUrl = environment.serverRoot;
  }

  loginPortalUrl(): string {
    return `${this.serverUrl}/login/portal`;
  }

  agreementUrl(agreementId?: string): string {
    let url = `${this.serverUrl}/api/agreement`;

    if (agreementId) {
      url += `/${agreementId}`;
    }

    return url;
  }

  deleteUserSignedAgreementUrl(userId: string, agreementId: string): string {
    return `${this.userUrl(userId)}/agreement/${agreementId}`;
  }

  downloadsUrl(releaseTag?: string): string {
    let url = `${this.serverUrl}/api/downloads`;

    if (releaseTag) {
      url += `/${releaseTag}`;
    }

    return url;
  }

  institutionUrl(institutionId?: string, params?: any): string {
    let url = `${this.serverUrl}/api/institution`;

    if (institutionId) {
      url += `/${institutionId}`;
    }

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  institutionsUrl(): string {
    return `${this.serverUrl}/api/institutions`;
  }

  scenarioUrl(scenarioId: string): string {
    return `${this.serverUrl}/api/scenario/${scenarioId}`;
  }

  scenariosUrl(): string {
    return `${this.serverUrl}/api/scenarios`;
  }

  scenarioReleaseUrl(scenarioReleaseId?: string): string {
    let url = `${this.serverUrl}/api/scenario/release`;

    if (scenarioReleaseId) {
      url += `/${scenarioReleaseId}`;
    }

    return url;
  }

  scenarioReleaseFileUrl(scenarioReleaseId: string): string {
    return `${this.serverUrl}/api/scenario/release/${scenarioReleaseId}/file`;
  }

  scenarioReleasesUrl(scenarioId: string): string {
    return `${this.serverUrl}/api/scenario/${scenarioId}/releases`;
  }

  headScenarioReleaseUrl(
    scenarioId: string,
    releaseTag?: string,
    params?: any,
  ): string {
    let url = `${this.serverUrl}/api/scenario/${scenarioId}/release`;
    if (releaseTag) {
      url += `/${releaseTag}`;
    }
    url += `/head`;

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  scenarioRevisionUrl(scenarioRevisionId?: string, params?: any): string {
    let url = `${this.serverUrl}/api/scenario/revision`;

    if (scenarioRevisionId) {
      url += `/${scenarioRevisionId}`;
    }

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  scenarioRevisionFileUrl(scenarioRevisionId: string): string {
    return `${this.serverUrl}/api/scenario/revision/${scenarioRevisionId}/file`;
  }

  scenarioRevisionsUrl(scenarioId: string, params?: any): string {
    let url = `${this.serverUrl}/api/scenario/${scenarioId}/revisions`;

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  scenarioPackageUrl(scenarioPackageId?: string, params?: any): string {
    let url = `${this.serverUrl}/api/scenario-package`;

    if (scenarioPackageId) {
      url += `/${scenarioPackageId}`;
    }

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  scenarioPackagesUrl(): string {
    return `${this.serverUrl}/api/scenario-packages`;
  }

  institutionScenarioPackageUrl(): string {
    return `${this.serverUrl}/api/institution/scenario-package`;
  }

  institutionScenarioPackagesUrl(institutionId: string, params?: any) {
    let url = `${this.serverUrl}/api/institution/${institutionId}/scenario-packages`;

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  scenarioPackageInstitutionsUrl(scenarioPackageId: string, params?: any) {
    let url = `${this.serverUrl}/api/scenario-package/${scenarioPackageId}/institutions`;

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  scenarioPackageItemUrl(): string {
    return `${this.serverUrl}/api/scenario-package/item`;
  }

  scenarioPackageItemsUrl(scenarioPackageId: string, params?: any): string {
    let url = `${this.serverUrl}/api/scenario-package/${scenarioPackageId}/items`;

    if (params) {
      const paramStrings = [];

      for (let param in params) {
        paramStrings.push(`${param}=${params[param].toString()}`);
      }

      url += `?${paramStrings.join('&')}`;
    }

    return url;
  }

  taxonomyUrl(taxonomyName?: string): string {
    let url = `${this.serverUrl}/api/taxonomy`;

    if (taxonomyName) {
      url += `/${taxonomyName}`;
    }

    return url;
  }

  userUrl(userId?: string): string {
    let url = `${this.serverUrl}/api/user`;

    if (userId) {
      url += `/${userId}`;
    }

    return url;
  }

  usersUrl(condensed?: boolean, includeInstitutions?: boolean): string {
    let url = `${this.serverUrl}/api/users`;

    if (condensed) {
      url += `/condensed`;
    }

    const params = [];
    if (includeInstitutions !== undefined) {
      params.push(`institutions=${includeInstitutions.toString()}`);
    }
    if (params.length) {
      url += `?${params.join('&')}`;
    }

    return url;
  }

  userPasswordUrl(): string {
    return `${this.serverUrl}/api/user/password`;
  }

  userPasswordResetUrl(): string {
    return `${this.serverUrl}/user/password/reset`;
  }

  userReleaseTagsUrl(userId: string): string {
    return `${this.serverUrl}/api/user/${userId}/releaseTags`;
  }

  userReleaseTagsWithInstitutionUrl(
    userId: string,
    institutionId: string,
  ): string {
    return `${this.serverUrl}/api/user/${userId}/releaseTags/${institutionId}`;
  }

  reactivateInstitutionUrl() {
    return `${this.serverUrl}/api/institution/reactivate`;
  }

  getUserUnsignedAgreementsRequestUrl(userId: string) {
    return `${this.userUrl(userId)}/agreements/unsigned`;
  }

  getAcceptAgreementRequestUrl(userId: string, agreementId: string) {
    return `${this.userUrl(userId)}/agreement/${agreementId}`;
  }
}
