import {
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInStaticPositionAnimation,
  fadeOutStaticPositionAnimation,
} from '@simx/shared/animations';
import { LoadingIndicatorStyles } from '@simx/modules/ui-elements/constants';
import { EntityLabelsInterface } from '../../../interfaces';
import { TableListItemComponent } from './table-list-item';

@Component({
  selector: 'table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutStaticPositionAnimation', [
      transition(':enter', [
        useAnimation(fadeInStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class TableListComponent {
  @ViewChild('removeAllItemsAction') removeAllItemsActionElement: ElementRef;

  @ContentChild('header') headerElement: ElementRef;

  @ContentChildren(TableListItemComponent)
  items!: QueryList<TableListItemComponent>;

  @Input() processing: boolean = false;
  @Input() message: string = null;
  @Input('displayRemoveAll') displayRemoveAllInput: boolean = true;
  @Input() displayItemCount: boolean = true;
  @Input() entityLabels: EntityLabelsInterface;
  @Input('classes') cssClasses: Array<string> = [];

  get displayRemoveAll() {
    return this.displayRemoveAllInput && this.items.length;
  }

  get displayHeader() {
    return !!this.headerElement;
  }

  get displayFooter() {
    return this.displayRemoveAllInput || this.displayItemCount;
  }

  loadingIndicatorStyles = LoadingIndicatorStyles;

  handleRemoveAllItemsAction() {
    this.removeAllItemsActionElement.nativeElement.blur();

    this.removeAllItems();
  }

  removeAllItems() {
    this.items?.toArray().forEach(item => item.remove());
  }
}
