import { Component, Input } from '@angular/core';
import { UIThemes } from '@simx/shared/constants';

@Component({
  selector: 'user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class UserMessageComponent {
  @Input() message: string = null;
  @Input() displayIcon: boolean = true;
  @Input() theme: UIThemes;
}
