import { Component, Input } from '@angular/core';

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class ErrorMessageComponent {
  @Input() message: string = null;
  @Input() displayIcon: boolean = true;
}
