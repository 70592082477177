import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import {
  ScenarioRevision,
  ScenarioRevisionIncludes,
} from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioRevisionDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getScenarioRevisionsForScenario(
    scenarioId: string,
    includes?: ScenarioRevisionIncludes,
  ): Promise<Array<ScenarioRevision>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioRevisionsUrl(scenarioId, includes);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const scenarioRevisions = response.map(
            item => new ScenarioRevision(item),
          );

          resolve(scenarioRevisions);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  createScenarioRevision(
    scenarioRevision: ScenarioRevision,
    includes?: ScenarioRevisionIncludes,
  ): Promise<ScenarioRevision> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioRevisionUrl(null, includes);
      const body = scenarioRevision;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const scenarioRevision = new ScenarioRevision(response);

          resolve(scenarioRevision);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  deleteScenarioRevision(scenarioRevisionId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = this.dataService.scenarioRevisionUrl(scenarioRevisionId);

      this.http
        .delete(url)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getScenarioRevisionDataFile(scenarioRevisionId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioRevisionFileUrl(scenarioRevisionId);

      this.http
        .get(url, { responseType: 'text' })
        .toPromise()
        .then((file: string) => {
          resolve(file);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
