import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  fadeInFromRightAnimation,
  fadeOutToRightAnimation,
} from '@simx/shared/animations';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services';

@Component({
  selector: 'authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInFromRightOutToRightAnimation', [
      transition(':enter', [
        useAnimation(fadeInFromRightAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToRightAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class AuthenticateComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  loginForm: FormGroup;

  loginFormErrorMessage: string = null;
  loginActionInProgress: boolean = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this._authenticationService
        .getIsLoggingIn$()
        .subscribe((state: boolean) => {
          this.loginActionInProgress = state;
        }),
    );

    this.subscriptions.add(
      this._authenticationService
        .getLoginFailed$()
        .subscribe((error: string) => {
          this.loginFormErrorMessage = error;
        }),
    );

    this.initializeLoginForm();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initializeLoginForm() {
    this.loginForm = this._formBuilder.group({
      password: ['', Validators.required],
      username: ['', Validators.required],
    });
  }

  onLoginFormSubmit() {
    this.loginFormErrorMessage = null;

    this._authenticationService.requestLogin({
      email: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    });

    this.loginForm.controls.password.reset();
  }
}
