import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppNavigationItem } from '../../models';
import { User } from '@simx/shared/models';
import { AppNavigationService, CoreService } from '../../services';
import { AuthenticationService } from '@simx/modules/authentication/services';
import { InstitutionAdministrationService } from '@simx/modules/institution/services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AppSidebarComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  appSidebarVisible: boolean = false;

  primaryNavigationItems: AppNavigationItem[] = [];
  userNavigationItems: AppNavigationItem[] = [];

  authenticatedUser: User = null;

  appReleaseVersion: string;

  institutionExpirationWindowMax!: number;
  urgentInstitutionExpirationWindowMax!: number;

  constructor(
    private appNavigationService: AppNavigationService,
    private authenticationService: AuthenticationService,
    private coreService: CoreService,
    private _institutionAdministrationService: InstitutionAdministrationService,
  ) {
    this.institutionExpirationWindowMax =
      this._institutionAdministrationService.institutionExpirationWindowMax;
    this.urgentInstitutionExpirationWindowMax =
      this._institutionAdministrationService.urgentInstitutionExpirationWindowMax;
  }

  ngOnInit() {
    this.appReleaseVersion = this.coreService.getAppReleaseVersion();

    this.subscriptions.add(
      this.appNavigationService.showAppSidebar$.subscribe((value: boolean) => {
        this.appSidebarVisible = value;
      }),
    );

    this.subscriptions.add(
      this.appNavigationService.primaryNavigationItems$.subscribe(
        (items: AppNavigationItem[]) => {
          this.primaryNavigationItems = items;
        },
      ),
    );

    this.subscriptions.add(
      this.appNavigationService.userNavigationItems$.subscribe(
        (items: AppNavigationItem[]) => {
          this.userNavigationItems = items;
        },
      ),
    );

    this.subscriptions.add(
      this.authenticationService.getUser$().subscribe((user: User) => {
        this.authenticatedUser = user;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
