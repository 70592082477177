import { Component, Input } from '@angular/core';
import { AccordionListComponent } from '../accordion-list.component';

@Component({
  selector: 'accordion-list-collapse-expand-toggle',
  templateUrl: './accordion-list-collapse-expand-toggle.component.html',
  styleUrls: ['./accordion-list-collapse-expand-toggle.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AccordionListCollapseExpandToggleComponent {
  @Input() accordionList: AccordionListComponent;

  handleExpandAllAction() {
    this.accordionList.expandAll();
  }

  handleCollapseAllAction() {
    this.accordionList.collapseAll();
  }
}
