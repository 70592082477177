export enum UserPermission {
  AccessDownloads = 'access_downloads',
  AccessHelp = 'access_help',
  AccessScenarioPackages = 'access_scenario_packages',
  AccessScenarioRevisionReleaseManagement = 'access_scenario_revision_release_management',
  AccessScenarios = 'access_scenarios',
  AdministerAllInstitutions = 'administer_all_institutions',
  AdministerAllScenarioPackages = 'administer_all_scenario_packages',
  AdministerAllScenarios = 'administer_all_scenarios',
  AdministerAllUsers = 'administer_all_users',
  AdministerAssociatedInstitutions = 'administer_associated_institutions',
  AdministerAssociatedScenarios = 'administer_associated_scenarios',
  CreateInstitutions = 'create_institutions',
  CreateScenarioPackages = 'create_scenario_packages',
  DeleteAnyScenarioPackage = 'delete_any_scenario_package',
}
