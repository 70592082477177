import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from '@simx/modules/authentication/guards';
import {
  AgreementComponent,
  AuthenticateComponent,
  RegisterComponent,
  ResetPasswordComponent,
  UnauthorizedComponent,
} from '../components';
import { SplashViewComponent } from '@simx/modules/core/components';

const routes: Routes = [
  {
    path: '',
    component: SplashViewComponent,
    children: [
      {
        path: 'agreement',
        component: AgreementComponent,
        data: {
          showAppHeader: false,
          showAppSidebar: false,
        },
        canActivate: [AuthorizedGuard],
      },
      {
        path: 'login',
        component: AuthenticateComponent,
        data: {
          showAppHeader: false,
          showAppSidebar: false,
        },
        canActivate: [AuthorizedGuard],
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
          showAppHeader: false,
          showAppSidebar: false,
        },
        canActivate: [AuthorizedGuard],
      },
    ],
  },
  {
    path: 'logout',
    canActivate: [AuthorizedGuard],
    children: [],
  },
  {
    path: 'register/:registrationToken',
    component: RegisterComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      title: 'Unauthorized',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class AuthenticationRoutingModule {}
