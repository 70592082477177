import { Component, Input, ViewEncapsulation } from '@angular/core';
import { expandFadeInFadeOutCollapseAnimation } from '@simx/shared/animations';

@Component({
  selector: 'list-filter-panel',
  templateUrl: './list-filter-panel.component.html',
  styleUrls: ['./list-filter-panel.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [expandFadeInFadeOutCollapseAnimation],
})
export class ListFilterPanelComponent {
  @Input() heading: string;

  get bodyAnimationState() {
    return this.displayBody ? 'expanded' : 'collapsed';
  }

  displayBody: boolean = false;

  handleHeaderClick() {
    this.toggleDisplayBody();
  }

  toggleDisplayBody() {
    this.displayBody = !this.displayBody;
  }
}
