import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { ScenarioPackageItem } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioPackageItemDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    includes?: any,
  ): Promise<Array<ScenarioPackageItem>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageItemsUrl(
        scenarioPackageId,
        includes,
      );

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const scenarioPackageItems = response.map(
            item => new ScenarioPackageItem(item),
          );

          resolve(scenarioPackageItems);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  setScenarioPackageItemsForScenarioPackage(
    scenarioPackageId: string,
    scenarioPackageItems: Array<ScenarioPackageItem>,
    includes?: any,
  ): Promise<Array<ScenarioPackageItem>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageItemsUrl(
        scenarioPackageId,
        includes,
      );
      const body = scenarioPackageItems;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: Array<any>) => {
          const scenarioPackageItems = response.map(
            item => new ScenarioPackageItem(item),
          );

          resolve(scenarioPackageItems);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  createScenarioPackageItem(
    scenarioPackageItem: ScenarioPackageItem,
  ): Promise<ScenarioPackageItem> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageItemUrl();
      const body = scenarioPackageItem;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const scenarioPackageItem = new ScenarioPackageItem(response);

          resolve(scenarioPackageItem);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  deleteScenarioPackageItem(
    scenarioPackageItem: ScenarioPackageItem,
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioPackageItemUrl();
      const options = {
        body: scenarioPackageItem,
      };

      this.http
        .request('delete', url, options)
        .toPromise()
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
