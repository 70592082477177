import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@simx/shared/module';
import { AuthenticationStoreModule } from './store';
import { AuthenticationRoutingModule } from './routing';
import { allComponents, publicComponents } from './components';

import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [...allComponents],
  imports: [
    AuthenticationStoreModule,
    AuthenticationRoutingModule,
    DialogModule,
    SharedModule,
    RouterModule,
  ],
  exports: [...publicComponents],
})
export class AuthenticationModule {}
