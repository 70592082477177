import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './taxonomy-store.reducer';
import { TaxonomyStoreEffects } from './taxonomy-store.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('taxonomy', reducer),
    EffectsModule.forFeature([TaxonomyStoreEffects]),
  ],
  providers: [TaxonomyStoreEffects],
})
export class TaxonomyStoreModule {}
