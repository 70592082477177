import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  expandFadeInFromLeftAnimation,
  fadeOutToLeftCollapseAnimation,
} from '@simx/shared/animations';
import {
  IconButtonIconTypes,
  TableListItemActionsBlockLocations,
} from '../../../../constants';

@Component({
  selector: 'table-list-item',
  templateUrl: './table-list-item.component.html',
  styleUrls: ['./table-list-item.component.scss'],
  animations: [
    trigger('expandFadeInFromLeftFadeOutToLeftCollapseAnimation', [
      transition(':enter', [
        useAnimation(expandFadeInFromLeftAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToLeftCollapseAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class TableListItemComponent {
  @HostBinding('@expandFadeInFromLeftFadeOutToLeftCollapseAnimation') true;

  @Input('id') itemId: string;
  @Input() actionsBlockLocation: TableListItemActionsBlockLocations =
    TableListItemActionsBlockLocations.Right;
  @Input() allowRemove: boolean;

  @Output('remove') removeEvent: EventEmitter<string> =
    new EventEmitter<string>();

  get displayActionsBlock() {
    return this.allowRemove;
  }

  iconButtonIconTypes = IconButtonIconTypes;

  handleRemoveItemAction() {
    this.remove();
  }

  remove() {
    this.removeEvent.emit(this.itemId);
  }
}
