import { Component } from '@angular/core';
import { UIThemes } from '@simx/shared/constants';

@Component({
  selector: 'two-panel-layout',
  templateUrl: './two-panel-layout.component.html',
  styleUrls: ['./two-panel-layout.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class TwoPanelLayoutComponent {
  uiThemes = UIThemes;
}
