import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'select-list-row',
  templateUrl: './select-list-row.component.html',
  styleUrls: ['./select-list-row.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SelectListRowComponent {
  @Input() active: boolean = false;
  @Input() disabled: boolean = false;
  @Input() content: any;

  @Output() click: EventEmitter<Event> = new EventEmitter<Event>();

  handleClick(event) {
    event.stopPropagation();

    this.click.emit(event);
  }
}
