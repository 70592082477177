import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './authentication-store.reducer';
import { AuthenticationStoreEffects } from './authentication-store.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('authentication', reducer),
    EffectsModule.forFeature([AuthenticationStoreEffects]),
  ],
  providers: [AuthenticationStoreEffects],
})
export class AuthenticationStoreModule {}
