import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { SelectListRows, SelectListEntityLabel } from '../../../models';
import { LoadingIndicatorStyles } from '../../../constants';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInStaticPositionAnimation,
  fadeOutStaticPositionAnimation,
} from '@simx/shared/animations';

@Component({
  selector: 'select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOutStaticPositionAnimation', [
      transition(':enter', [
        useAnimation(fadeInStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class SelectListComponent implements OnChanges {
  @Input('rows') rowData: SelectListRows;
  @Input() entityLabels: SelectListEntityLabel = {
    singular: 'Item',
    plural: 'Items',
  };
  @Input() loading: boolean;
  @Input() disabled: boolean;

  @Input() totalRowCount: number = null;

  rowIds: Array<string> = [];
  activeRowIds: Array<string> = [];
  @Output() rowsSelected: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();

  get displayBulkSelectToggle() {
    return this.rowIds.length > 0;
  }
  get bulkSelectToggleDisabled() {
    return this.disabled;
  }

  get itemCountLabel() {
    let itemCountLabel = '';

    if (
      this.totalRowCount !== null &&
      this.rowIds.length !== this.totalRowCount
    ) {
      itemCountLabel += `${this.rowIds.length} of ${this.totalRowCount}`;
    } else {
      itemCountLabel += `${this.rowIds.length}`;
    }

    itemCountLabel += ` ${
      this.totalRowCount === 1
        ? this.entityLabels.singular
        : this.entityLabels.plural
    }`;

    if (this.activeRowIds.length) {
      itemCountLabel += `, ${this.activeRowIds.length} selected`;
    }

    return itemCountLabel;
  }

  loaderStyles = LoadingIndicatorStyles;

  ngOnChanges(changes) {
    if (changes.rowData?.currentValue) {
      this.rowIds = Object.keys(this.rowData);

      if (this.activeRowIds.length) {
        let previouslyActiveRowIdEliminated = false;

        for (let i = 0; i < this.activeRowIds.length; i++) {
          if (!this.rowIds.includes(this.activeRowIds[i])) {
            previouslyActiveRowIdEliminated = true;
            break;
          }
        }

        if (previouslyActiveRowIdEliminated) {
          const filteredActiveRowIds = this.activeRowIds.filter(rowId =>
            this.rowIds.includes(rowId),
          );
          this.setActiveRows(filteredActiveRowIds);
        }
      }
    }
  }

  handleRowClick(event: PointerEvent, clickedRowId: string) {
    if (this.disabled) return;

    const activeRowIdIndex = this.activeRowIds.indexOf(clickedRowId);
    const rowIsAlreadyActive = activeRowIdIndex > -1;

    let newActiveRowIds: Array<string> = [];

    // Cmd key
    if (event.metaKey || event.ctrlKey) {
      if (rowIsAlreadyActive) {
        newActiveRowIds = this.activeRowIds.filter(
          activeRowId => activeRowId !== clickedRowId,
        );
      } else {
        newActiveRowIds = [...this.activeRowIds, clickedRowId];
      }
    }
    // Shift key
    else if (event.shiftKey) {
      if (!this.activeRowIds.length) {
        newActiveRowIds = this.rowIds.slice(
          0,
          this.rowIds.indexOf(clickedRowId) + 1,
        );
      } else if (this.activeRowIds.length === 1) {
        if (rowIsAlreadyActive) {
          newActiveRowIds = [...this.activeRowIds];
        } else {
          const previouslyActivatedRowIdIndex = this.rowIds.indexOf(
            this.activeRowIds[0],
          );
          const presentlyActivatedRowIdIndex =
            this.rowIds.indexOf(clickedRowId);

          const previouslySelectedRowIsAbovePresentlySelectedRow =
            previouslyActivatedRowIdIndex < presentlyActivatedRowIdIndex;

          const newBeginningActiveRowId =
            previouslySelectedRowIsAbovePresentlySelectedRow
              ? previouslyActivatedRowIdIndex
              : presentlyActivatedRowIdIndex;
          const newEndingActiveRowId =
            previouslySelectedRowIsAbovePresentlySelectedRow
              ? presentlyActivatedRowIdIndex
              : previouslyActivatedRowIdIndex;

          newActiveRowIds = this.rowIds.slice(
            newBeginningActiveRowId,
            newEndingActiveRowId + 1,
          );
        }
      }
      // @CMN-TODO Finish "Shift" key handlers
      // else {
      //   if (rowIsAlreadyActive) {
      //
      // }
      // else {
      //   const activeRowIdIndexes = this.activeRowIds.map(rowId => this.rowIds.indexOf(rowId));
      //   activeRowIdIndexes.sort((a, b) => (a - b));
      //
      // const previouslySelectedRowsAreConsecutive = (
      //   ((activeRowIdIndexes[activeRowIdIndexes.length - 1] - activeRowIdIndexes[0]) + 1) ===
      //   activeRowIdIndexes.length
      // );
      //
      // if (previouslySelectedRowsAreConsecutive) {
      //   const presentlyActivatedRowIdIndex = this.rowIds.indexOf(clickedRowId);
      //
      // }
      // newActiveRowIds = this.activeRowIds;
      // }
      //       }
      // else {
      //   const clickedRowIndex = this.rows.indexOf(clickedRowId);
      //   let targetRowIndex = null;
      //
      //   let i = (clickedRowIndex - 1);
      //   while (i >= 0) {
      //     if (this.activeRows.includes(this.rows[i])) {
      //       targetRowIndex = i;
      //       break;
      //     }
      //     i--;
      //   }
      //   if (targetRowIndex === null) {
      //     i = (clickedRowIndex + 1);
      //     while (i < this.rows.length) {
      //       if (this.activeRows.includes(this.rows[i])) {
      //         targetRowIndex = i;
      //         break;
      //       }
      //       i++;
      //     }
      //   }
      //   if (targetRowIndex === null) {
      //     newActiveRows = [clickedRowId];
      //   }
      //   else {
      // const currentlyActiveRowsAreConsecutive = (
      //   this.activeRows.length > 1 &&
      //   (this.activeRows[this.activeRows.length - 1] -
      //       this.activeRows[0]) === (this.activeRows.length - 1)
      // );
      //       if (currentlyActiveRowsAreConsecutive) {
      //         if (targetRowIndex > rowIndex) {
      //           targetRowIndex = currentlyActiveRows[(currentlyActiveRows.length - 1)];
      //         }
      //         else {
      //           targetRowIndex = currentlyActiveRows[0];
      //         }
      //       }
      //
      //       newActiveRows = [];
      //       newActiveRows.push(rowIndex);
      //       if (targetRowIndex > rowIndex) {
      //         for (i = (rowIndex + 1); i <= targetRowIndex; i++) {
      //           newActiveRows.push(i);
      //         }
      //       }
      //       else if (targetRowIndex < rowIndex) {
      //         for (i = (rowIndex - 1); i >= targetRowIndex; i--) {
      //           newActiveRows.push(i);
      //         }
      //       }
      //       else {
      //         newActiveRows = [rowIndex];
      //       }
      // }
      // }
    }
    // No key
    else {
      if (
        rowIsAlreadyActive &&
        this.activeRowIds.length === 1 &&
        this.activeRowIds[0] === clickedRowId
      ) {
        newActiveRowIds = [];
      } else {
        newActiveRowIds = [clickedRowId];
      }
    }

    this.setActiveRows(newActiveRowIds);
  }

  setActiveRows(newActiveRowIds: Array<string>) {
    newActiveRowIds.sort((a, b) => {
      return this.rowIds.indexOf(a) - this.rowIds.indexOf(b);
    });

    this.activeRowIds = newActiveRowIds;
    this.rowsSelected.emit(this.activeRowIds);
  }

  rowIsDisabled(rowId: string): boolean {
    return (
      this.rowData[rowId].disabled ||
      (this.disabled && !this.activeRowIds.includes(rowId))
    );
  }

  rowIsActive(rowId: string): boolean {
    return this.activeRowIds.includes(rowId);
  }

  selectAllRows() {
    this.setActiveRows([...this.rowIds]);
  }

  deselectAllRows() {
    this.setActiveRows([]);
  }

  handleBulkSelectToggleClick(event: Event) {
    event.stopPropagation();

    if (this.disabled) {
      return;
    }

    if (this.activeRowIds.length === this.rowIds.length) {
      this.deselectAllRows();
    } else {
      this.selectAllRows();
    }
  }

  handleBodyClick(event) {
    if (this.disabled) return;

    this.deselectAllRows();
  }
}
