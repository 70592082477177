import { Component, Input } from '@angular/core';
import { expandFadeInFadeOutCollapseAnimation } from '@simx/shared/animations';
import { AccordionListItemViewStates } from '../../../../../constants';

@Component({
  selector: 'accordion-list-item-body',
  templateUrl: './accordion-list-item-body.component.html',
  styleUrls: ['./accordion-list-item-body.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  animations: [expandFadeInFadeOutCollapseAnimation],
})
export class AccordionListItemBodyComponent {
  @Input('viewState') currentViewState: AccordionListItemViewStates;

  get expandCollapseAnimationState() {
    return this.currentViewState === AccordionListItemViewStates.Collapsed
      ? 'collapsed'
      : 'expanded';
  }
}
