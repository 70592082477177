import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'date' })
export class DatePipe implements PipeTransform {
  transform(value: string): string {
    const dateValue = new Date(value);

    const dateFormatOptions: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return dateValue.toLocaleDateString('en-US', dateFormatOptions);
  }
}
