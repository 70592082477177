import { Injectable } from '@angular/core';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as InstitutionStoreActions from './institution-store.actions';
import * as InstitutionStoreSelectors from './institution-store.selectors';
import * as AuthenticationStoreActions from '@simx/modules/authentication/store/authentication-store.actions';
import { InstitutionDataService } from '@simx/shared/services';
import { Institution } from '@simx/shared/models';

@Injectable()
export class InstitutionStoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private institutionDataService: InstitutionDataService,
  ) {}

  checkInstitutionListLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstitutionStoreActions.ActionTypes.CheckInstitutionListLoaded),
      withLatestFrom(
        this.store.select(
          InstitutionStoreSelectors.selectHasLoadedInstitutionList,
        ),
        this.store.select(
          InstitutionStoreSelectors.selectIsLoadingInstitutionList,
        ),
      ),
      filter(([_, hasLoaded, isLoading]) => !hasLoaded && !isLoading),
      map(() => InstitutionStoreActions.loadInstitutionListRequested()),
    ),
  );

  loadInstitutionListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstitutionStoreActions.ActionTypes.LoadInstitutionListRequested),
      switchMap(() =>
        this.institutionDataService
          .getInstitutions()
          .then((institutions: Array<Institution>) =>
            InstitutionStoreActions.loadInstitutionListSucceeded({
              institutions,
            }),
          )
          .catch((error: string) =>
            InstitutionStoreActions.loadInstitutionListFailed({ error }),
          ),
      ),
    ),
  );

  loadInstitutionListSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InstitutionStoreActions.ActionTypes.LoadInstitutionListSucceeded),
      map(({ institutions }) =>
        InstitutionStoreActions.loadInstitutionList({
          institutions,
        }),
      ),
    ),
  );

  clearInstitutionStoreAndLoadInstitutionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        InstitutionStoreActions.ActionTypes
          .ClearInstitutionStoreAndLoadInstitutionList,
      ),
      map(() => InstitutionStoreActions.loadInstitutionListRequested()),
    ),
  );

  clearInstitutionStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationStoreActions.ActionType.LogoutSucceeded),
      map(() => InstitutionStoreActions.clearInstitutionStore()),
    ),
  );
}
