import { Institution } from './institution.model';

export class InstitutionUser {
  institution?: Institution;
  institutionId?: string;
  institutionPermissions?: Array<string>;
  scenarioReleaseTags?: Array<string>;
  userId?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('institution')) {
        this.institution = new Institution(data.institution);
      }
      if (data.hasOwnProperty('institutionId')) {
        this.institutionId = data.institutionId;
      }
      if (data.hasOwnProperty('institutionPermissions')) {
        this.institutionPermissions = data.institutionPermissions;
      }
      if (data.hasOwnProperty('scenarioReleaseTags')) {
        this.scenarioReleaseTags = data.scenarioReleaseTags;
      }
      if (data.hasOwnProperty('userId')) {
        this.userId = data.userId;
      }
    }
  }
}
