export class ScenarioReleaseTag {
  currentHeadScenarioReleaseId?: string;
  releaseTag?: string;
  scenarioId?: string;
  scenarioReleaseTagId?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('currentHeadScenarioReleaseId')) {
        this.currentHeadScenarioReleaseId = data.currentHeadScenarioReleaseId;
      }
      if (data.hasOwnProperty('releaseTag')) {
        this.releaseTag = data.releaseTag;
      }
      if (data.hasOwnProperty('scenarioId')) {
        this.scenarioId = data.scenarioId;
      }
      if (data.hasOwnProperty('scenarioReleaseTagId')) {
        this.scenarioReleaseTagId = data.scenarioReleaseTagId;
      }
    }
  }
}
