import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import {
  Agreement,
  CondensedUser,
  LoginResponse,
  PasswordChangeRequest,
  PasswordResetRequest,
  User,
  UserSignedAgreement,
} from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class UserDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getUsers(): Promise<Array<User>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.usersUrl();

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const users = response.map(item => new User(item));

          resolve(users);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getCondensedUsers(
    includeInstitutions?: boolean,
  ): Promise<Array<CondensedUser>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.usersUrl(true, includeInstitutions);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const condensedUsers = response.map(item => new CondensedUser(item));

          resolve(condensedUsers);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getUser(userId: string): Promise<User> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.userUrl(userId);

      this.http
        .get(url)
        .toPromise()
        .then((response: any) => {
          const user = new User(response);

          resolve(user);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getUserReleaseTags(userId: string): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.userReleaseTagsUrl(userId);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<string>) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  createUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.userUrl();
      const body = user;

      this.http
        .put(url, body)
        .toPromise()
        .then((response: any) => {
          const user = new User(response);

          resolve(user);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  updateUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.userUrl();
      const body = user;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: any) => {
          const user = new User(response);

          resolve(user);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  updateUserPassword(request: PasswordChangeRequest): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.userPasswordUrl();
      const body = request;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: LoginResponse) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  resetUserPassword(request: PasswordResetRequest): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.userPasswordResetUrl();
      const body = request;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: any) => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getUnsignedAgreements(userId: string): Promise<Array<Agreement>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.getUserUnsignedAgreementsRequestUrl(userId);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<Agreement>) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  signAgreement(
    userId: string,
    agreementId: string,
  ): Promise<UserSignedAgreement> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.getAcceptAgreementRequestUrl(
        userId,
        agreementId,
      );
      const body = {};

      this.http
        .post(url, body)
        .toPromise()
        .then((response: UserSignedAgreement) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  unsignAgreement(userId: string, agreementId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.deleteUserSignedAgreementUrl(
        userId,
        agreementId,
      );

      this.http
        .request('delete', url)
        .toPromise()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
