import { Component, Input, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { TaxonomyService } from '@simx/modules/taxonomy/services';
import { TaxonomyItem } from '@simx/shared/models';
import { CheckListSelectItem } from '@simx/modules/ui-elements/models';
import {
  EntityDetailDisplayModes,
  ReleaseTagPreferredOrder,
} from '@simx/shared/constants';
import { Taxonomies } from '@simx/modules/taxonomy/constants';
import { LoadingIndicatorStyles } from '@simx/modules/ui-elements/constants';

@Component({
  selector: 'release-tag-list',
  templateUrl: './release-tag-list.component.html',
  styleUrls: ['./release-tag-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ReleaseTagListComponent,
    },
  ],
})
export class ReleaseTagListComponent implements ControlValueAccessor, OnInit {
  @Input() id: string;
  @Input() displayMode: EntityDetailDisplayModes;

  releaseTags: Array<string>;

  form: FormGroup;
  checkListSelectItems: Array<CheckListSelectItem>;

  hasLoadedAvailableReleaseTags: boolean = false;

  displayModes = EntityDetailDisplayModes;
  taxonomies = Taxonomies;
  loadingIndicatorStyles = LoadingIndicatorStyles;

  onChange = _ => {};
  onTouched = () => {};
  touched: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private taxonomyService: TaxonomyService,
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.taxonomyService
      .getTaxonomy(Taxonomies.ReleaseTag)
      .then((taxonomy: Array<TaxonomyItem>) => {
        this.checkListSelectItems = taxonomy.map(taxonomyItem => ({
          value: taxonomyItem.term,
          label: taxonomyItem.label,
        }));
        this.checkListSelectItems.sort(
          (a, b) =>
            ReleaseTagPreferredOrder.indexOf(a.label) -
            ReleaseTagPreferredOrder.indexOf(b.label),
        );
        this.hasLoadedAvailableReleaseTags = true;
      });
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      releaseTags: [[]],
    });

    this.form.valueChanges.subscribe(data => {
      this.releaseTags = data.releaseTags;
      this.onChange(this.releaseTags);
    });
  }

  setFormValues() {
    this.form.controls.releaseTags.setValue(this.releaseTags);
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  writeValue(value: Array<string>) {
    value.sort(
      (a, b) =>
        ReleaseTagPreferredOrder.indexOf(a) -
        ReleaseTagPreferredOrder.indexOf(b),
    );
    this.releaseTags = value;
    this.setFormValues();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
