import { InstitutionUser } from './institution-user.model';

export class User {
  createdAt?: string;
  email?: string;
  institutions?: Array<InstitutionUser>;
  lastLoginAt?: string;
  name?: string;
  password?: string;
  tags?: Array<string>;
  updatedAt?: string;
  userAgreements?: Array<any>;
  userId?: string;
  userPermissions?: Array<string>;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('createdAt')) {
        this.createdAt = data.createdAt;
      }
      if (data.hasOwnProperty('email')) {
        this.email = data.email;
      }
      if (data.hasOwnProperty('institutions')) {
        this.institutions = data.institutions.map(
          item => new InstitutionUser(item),
        );
      }
      if (data.hasOwnProperty('lastLoginAt')) {
        this.lastLoginAt = data.lastLoginAt;
      }
      if (data.hasOwnProperty('name')) {
        this.name = data.name;
      }
      if (data.hasOwnProperty('password')) {
        this.password = data.password;
      }
      if (data.hasOwnProperty('tags')) {
        this.tags = data.tags;
      }
      if (data.hasOwnProperty('updatedAt')) {
        this.updatedAt = data.updatedAt;
      }
      if (data.hasOwnProperty('userAgreements')) {
        this.userAgreements = data.userAgreements;
      }
      if (data.hasOwnProperty('userId')) {
        this.userId = data.userId;
      }
      if (data.hasOwnProperty('userPermissions')) {
        this.userPermissions = data.userPermissions;
      }
    }
  }
}
