import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import {
  ScenarioRelease,
  ScenarioReleaseTag,
  ScenarioReleaseTagMap,
} from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioReleaseTagDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getHeadScenarioReleasesForScenario(
    scenarioId: string,
    releaseTags?: Array<string>,
  ): Promise<ScenarioReleaseTagMap> {
    return new Promise((resolve, reject) => {
      let params = null;

      if (releaseTags) {
        params = {
          releaseTag: releaseTags.join(','),
        };
      }

      const url = this.dataService.headScenarioReleaseUrl(
        scenarioId,
        null,
        params,
      );

      this.http
        .get(url)
        .toPromise()
        .then((response: any) => {
          const scenarioReleaseTagMap: ScenarioReleaseTagMap = {};

          for (let releaseTag in response) {
            scenarioReleaseTagMap[releaseTag] = {
              scenarioRelease: response[releaseTag].scenarioRelease
                ? new ScenarioRelease(response[releaseTag].scenarioRelease)
                : null,
            };
          }

          resolve(scenarioReleaseTagMap);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  setHeadScenarioReleaseForScenarioAndReleaseTag(
    scenarioId: string,
    releaseTag: string,
    scenarioReleaseId: string,
  ): Promise<ScenarioReleaseTag> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.headScenarioReleaseUrl(scenarioId);
      const body: ScenarioReleaseTagMap = {
        [releaseTag]: {
          scenarioReleaseId,
        },
      };

      this.http
        .put(url, body)
        .toPromise()
        .then((response: ScenarioReleaseTagMap) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  unsetHeadScenarioReleaseForScenarioAndReleaseTag(
    scenarioId: string,
    releaseTag: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.headScenarioReleaseUrl(
        scenarioId,
        releaseTag,
      );

      this.http
        .delete(url)
        .toPromise()
        .then(() => {
          resolve();
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
