import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { expandFadeInFadeOutCollapseAnimation } from '@simx/shared/animations';
import { InteractionPanelViewStates } from '../../../constants';

@Component({
  selector: 'interaction-panel',
  templateUrl: './interaction-panel.component.html',
  styleUrls: ['./interaction-panel.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [expandFadeInFadeOutCollapseAnimation],
})
export class InteractionPanelComponent implements OnChanges {
  @Input() heading: string;
  @Input('displayAction') displayActionBlock: boolean;

  @Input('viewState') currentViewState: InteractionPanelViewStates =
    InteractionPanelViewStates.Collapsed;

  @Output('viewStateChange')
  viewStateChangeEvent: EventEmitter<InteractionPanelViewStates> =
    new EventEmitter<InteractionPanelViewStates>();

  get panelAnimationState() {
    return this.currentViewState === InteractionPanelViewStates.Collapsed
      ? 'collapsed'
      : 'expanded';
  }
  get actionBlockAnimationState() {
    return this.displayActionBlock ? 'expanded' : 'collapsed';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewState) {
      if (changes.viewState.currentValue !== this.currentViewState) {
        this.setViewState(changes.viewState.currentValue);
      }
    }
  }

  handleHeaderClick() {
    this.toggleExpandedCollapsedViewState();
  }

  setViewState(viewState: InteractionPanelViewStates) {
    this.currentViewState = viewState;
    this.viewStateChangeEvent.emit(viewState);
  }

  toggleExpandedCollapsedViewState() {
    const newViewState =
      this.currentViewState === InteractionPanelViewStates.Expanded
        ? InteractionPanelViewStates.Collapsed
        : InteractionPanelViewStates.Expanded;
    this.setViewState(newViewState);
  }
}
