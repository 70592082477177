import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Scenario } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class ScenarioDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getScenario(scenarioId: string): Promise<Scenario> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioUrl(scenarioId);

      this.http
        .get(url)
        .toPromise()
        .then((response: any) => {
          const scenario = new Scenario(response);

          resolve(scenario);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  updateScenario(scenario: Scenario): Promise<Scenario> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenarioUrl(scenario.scenarioId);
      const body = scenario;

      this.http
        .post(url, body)
        .toPromise()
        .then((response: any) => {
          const user = new Scenario(response);

          resolve(user);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  getScenarios(): Promise<Array<Scenario>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.scenariosUrl();

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const scenarios = response.map(item => new Scenario(item));

          resolve(scenarios);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
