import { Institution } from './institution.model';
import { ScenarioPackage } from './scenario-package.model';

export class InstitutionScenarioPackage {
  institution?: Institution;
  institutionId?: string;
  scenarioPackage?: ScenarioPackage;
  scenarioPackageId?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('institution')) {
        this.institution = new Institution(data.institution);
      }
      if (data.hasOwnProperty('institutionId')) {
        this.institutionId = data.institutionId;
      }
      if (data.hasOwnProperty('scenarioPackage')) {
        this.scenarioPackage = new ScenarioPackage(data.scenarioPackage);
      }
      if (data.hasOwnProperty('scenarioPackageId')) {
        this.scenarioPackageId = data.scenarioPackageId;
      }
    }
  }
}
