import { Component, Input } from '@angular/core';
import { EntityDetailDividerStyles } from '@simx/shared/constants';

@Component({
  selector: 'entity-detail-divider',
  templateUrl: './entity-detail-divider.component.html',
  styleUrls: ['./entity-detail-divider.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class EntityDetailDividerComponent {
  @Input() style: EntityDetailDividerStyles = EntityDetailDividerStyles.Light;
}
