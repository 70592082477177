import { Component } from '@angular/core';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  host: {
    class: 'angular-component-wrapper',
  },
})
export class UnauthorizedComponent {}
