import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IconButtonIconTypes } from '../../../constants';

@Component({
  selector: 'icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class IconButtonComponent {
  @ViewChild('button') buttonElement: ElementRef;

  @Input() icon: IconButtonIconTypes;
  @Input() title: string = '';
  @Input() classes: Array<string> = [];
  @Input() disabled: boolean = false;

  @Output() clique: EventEmitter<Event> = new EventEmitter<Event>();

  get classString() {
    return ['simx-icon-button', ...this.classes].join(' ');
  }

  iconTypes = IconButtonIconTypes;

  handleClick(event: Event) {
    event.stopPropagation();

    this.buttonElement.nativeElement.blur();

    if (this.disabled) {
      return;
    }

    this.clique.emit(event);
  }
}
