import {
  animate,
  animation,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeInFromLeftAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 0,
        transform: 'translateX(-10px)',
        offset: 0,
      }),
      style({
        opacity: 1,
        transform: 'none',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeOutToLeftAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 1,
        transform: 'none',
        offset: 0,
      }),
      style({
        opacity: 0,
        transform: 'translateX(-10px)',
        offset: 1,
      }),
    ]),
  ),
]);

export const expandFadeInFromLeftAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease-in-out',
    keyframes([
      style({
        height: 0,
        opacity: 0,
        transform: 'translateX(-10px)',
        offset: 0,
      }),
      style({
        height: '*',
        opacity: 0,
        transform: 'translateX(-10px)',
        offset: 0.5,
      }),
      style({
        opacity: 1,
        transform: 'none',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeOutToLeftCollapseAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease-out',
    keyframes([
      style({
        height: '*',
        opacity: 1,
        transform: 'none',
        offset: 0,
      }),
      style({
        height: '*',
        opacity: 0,
        transform: 'translateX(-10px)',
        offset: 0.5,
      }),
      style({
        height: 0,
        opacity: 0,
        transform: 'translateX(-10px)',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeInFromRightAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 0,
        transform: 'translateX(10px)',
        offset: 0,
      }),
      style({
        opacity: 1,
        transform: 'none',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeOutToRightAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 1,
        transform: 'none',
        offset: 0,
      }),
      style({
        opacity: 0,
        transform: 'translateX(10px)',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeInFromTopAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 0,
        transform: 'translateY(-5px)',
        offset: 0,
      }),
      style({
        opacity: 1,
        transform: 'none',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeOutToTopAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 1,
        transform: 'none',
        offset: 0,
      }),
      style({
        opacity: 0,
        transform: 'translateY(-5px)',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeInStaticPositionAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 0,
        offset: 0,
      }),
      style({
        opacity: 1,
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeOutStaticPositionAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 1,
        offset: 0,
      }),
      style({
        opacity: 0,
        offset: 1,
      }),
    ]),
  ),
]);

export const expandFadeInAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 0,
        height: 0,
        offset: 0,
      }),
      style({
        opacity: 0,
        height: '*',
        offset: 0.75,
      }),
      style({
        opacity: 1,
        height: '*',
        offset: 1,
      }),
    ]),
  ),
]);

export const fadeOutCollapseAnimation = animation([
  animate(
    '{{ duration }} {{ delay }} ease',
    keyframes([
      style({
        opacity: 1,
        height: '*',
        offset: 0,
      }),
      style({
        opacity: 0,
        height: '*',
        offset: 0.25,
      }),
      style({
        opacity: 0,
        height: 0,
        offset: 1,
      }),
    ]),
  ),
]);

export const expandFadeInFadeOutCollapseAnimation = trigger(
  'expandFadeInFadeOutCollapseAnimation',
  [
    state(
      'expanded',
      style({
        height: '*',
        opacity: 1,
      }),
    ),
    state(
      'collapsed',
      style({
        height: 0,
        opacity: 0,
        overflow: 'hidden',
      }),
    ),
    transition('collapsed => expanded', [
      animate(
        '0.4s 0s ease',
        keyframes([
          style({
            opacity: 0,
            height: 0,
            offset: 0,
          }),
          style({
            opacity: 0,
            height: '*',
            offset: 0.5,
          }),
          style({
            opacity: 1,
            height: '*',
            offset: 1,
          }),
        ]),
      ),
    ]),
    transition('expanded => collapsed', [
      animate(
        '0.4s 0s ease',
        keyframes([
          style({
            opacity: 1,
            height: '*',
            offset: 0,
          }),
          style({
            opacity: 0,
            height: '*',
            offset: 0.5,
          }),
          style({
            opacity: 0,
            height: 0,
            offset: 1,
          }),
        ]),
      ),
    ]),
  ],
);
