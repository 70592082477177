import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthenticationService } from '../services';

const unauthenticatedPaths = ['login', 'reset-password', 'agreement'];

@Injectable({ providedIn: 'root' })
export class AuthorizedGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const requiredPermissions: string[] = route.data?.permissions || [];
    const path = route.routeConfig.path;

    return new Promise(resolve => {
      if (path === 'logout') {
        this.authenticationService.requestLogout();
        resolve(true);
        return;
      }

      this.authenticationService
        .getIsLoggedIn$()
        .pipe(take(1))
        .subscribe((authenticated: boolean) => {
          // User is not logged in. Redirect to Login page if necessary
          // and prevent authorization of requested route.
          if (!authenticated) {
            if (unauthenticatedPaths.includes(path)) {
              resolve(true);
            } else {
              this.router.navigate(['/login']);
              resolve(false);
            }
          }

          // User is logged in, must validate token.
          else {
            this.authenticationService
              .validateToken()
              .then(() => {
                // Redirect user to default page if they are already logged in
                // and attempt to navigate to Login page.
                if (unauthenticatedPaths.includes(path)) {
                  this.router.navigate(['/']);
                  resolve(false);
                }
                // Route does not specify roles, therefore it's available
                // to all users.
                else if (!requiredPermissions.length) {
                  resolve(true);
                } else {
                  let isAllowed = false;
                  let i = 0;
                  while (!isAllowed && i < requiredPermissions.length) {
                    isAllowed = this.authenticationService.isAllowed(
                      requiredPermissions[i],
                    );
                    i++;
                  }
                  if (!isAllowed) {
                    this.router.navigate(['/unauthorized']);
                  }
                  resolve(isAllowed);
                }
              })
              .catch(() => {
                this.router.navigate(['/logout']);
                resolve(false);
              });
          }
        });
    });
  }
}
