import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  fadeInFromRightAnimation,
  fadeOutToRightAnimation,
} from '@simx/shared/animations';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services';
import { EntityDetailDividerStyles } from '@simx/shared/constants';
import { Agreement } from '@simx/shared/models';

@Component({
  selector: 'agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInFromRightOutToRightAnimation', [
      transition(':enter', [
        useAnimation(fadeInFromRightAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToRightAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class AgreementComponent implements OnDestroy, OnInit {
  subscriptions: Subscription = new Subscription();

  dividerStyles = EntityDetailDividerStyles;

  unsignedAgreement: Agreement | null = null;

  acceptanceErrorMessage: string | null = null;
  acceptanceInProgress: boolean = false;
  showRejectionDialog: boolean = false;

  constructor(private _authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.subscriptions.add(
      this._authenticationService
        .getUnsignedAgreements$()
        .subscribe((unsignedAgreements: Array<Agreement>) => {
          this.unsignedAgreement = [...unsignedAgreements].shift() || null;
        }),
    );

    this.subscriptions.add(
      this._authenticationService
        .getIsSigningAgreement$()
        .subscribe((acceptanceState: boolean) => {
          this.acceptanceInProgress = acceptanceState;
        }),
    );

    this.subscriptions.add(
      this._authenticationService
        .getSignAgreementFailed$()
        .subscribe((error: string) => {
          this.acceptanceErrorMessage = error;
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleDialog() {
    this.showRejectionDialog = !this.showRejectionDialog;
  }

  handleDisagreeActionClick() {
    this.toggleDialog();
  }

  onAgreementRejectionSubmit() {
    this.showRejectionDialog = false;
    this._authenticationService.requestLogout();
  }

  onAgreementAcceptanceSubmit() {
    this.acceptanceErrorMessage = null;

    this._authenticationService.requestSignAgreement(
      this.unsignedAgreement.agreementId,
    );
  }
}
