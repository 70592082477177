export enum IconButtonIconTypes {
  Filter = 'FILTER',
  HorizontalEllipsis = 'HORIZONTAL_ELLIPSIS',
  MagnifyingGlass = 'MAGNIFYING_GLASS',
  PartialCircleWithArrow = 'PARTIAL_CIRCLE_WITH_ARROW',
  Plus = 'PLUS',
  Trash = 'TRASH',
  TripleHorizontalBar = 'TRIPLE_HORIZONTAL_BAR',
  X = 'X',
}
