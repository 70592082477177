import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppNavigationService } from '../../services';
import { AuthenticationService } from '@simx/modules/authentication/services';
import {
  transition,
  useAnimation,
  trigger,
  AnimationEvent,
} from '@angular/animations';
import {
  fadeInFromLeftAnimation,
  fadeOutToLeftAnimation,
} from '@simx/shared/animations';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  animations: [
    trigger('fadeInFromLeftOutToLeftAnimation', [
      transition('void => visible', [
        useAnimation(fadeInFromLeftAnimation, {
          params: {
            duration: '0.15s',
            delay: '0s',
          },
        }),
      ]),
      transition('hidden => visible', [
        useAnimation(fadeInFromLeftAnimation, {
          params: {
            duration: '0.15s',
            delay: '0s',
          },
        }),
      ]),
      transition('visible => hidden', [
        useAnimation(fadeOutToLeftAnimation, {
          params: {
            duration: '0.15s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class AppHeaderComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  appHeaderVisible: boolean;
  accommodateAppSidebar: boolean;
  appViewTitle: string;
  appViewTitleAnimationState: string = 'void';
  displayedAppViewTitle: string;

  constructor(private appNavigationService: AppNavigationService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.appNavigationService.showAppHeader$.subscribe((value: boolean) => {
        this.appHeaderVisible = value;
      }),
    );

    this.subscriptions.add(
      this.appNavigationService.showAppSidebar$.subscribe((value: boolean) => {
        this.accommodateAppSidebar = value;
      }),
    );

    this.subscriptions.add(
      this.appNavigationService.appViewTitle$.subscribe((title: string) => {
        this.appViewTitle = title;
        if (this.appViewTitleAnimationState === 'void' && title !== null) {
          this.displayedAppViewTitle = title;
          this.appViewTitleAnimationState = 'visible';
        } else if (this.appViewTitleAnimationState === 'visible') {
          this.appViewTitleAnimationState = 'hidden';
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  appViewTitleAnimationDone(event: AnimationEvent) {
    if (event.fromState === 'void' && event.toState === 'visible') {
      this.displayedAppViewTitle = this.appViewTitle;
    } else if (event.fromState === 'visible' && event.toState === 'hidden') {
      this.displayedAppViewTitle = this.appViewTitle;
      this.appViewTitleAnimationState = 'visible';
    }
  }
}
