import { AgreementComponent } from './agreement';
import { AuthenticateComponent } from './authenticate';
import { RegisterComponent } from './register';
import { ResetPasswordComponent } from './reset-password';
import { UnauthorizedComponent } from './unauthorized';

export const allComponents: any[] = [
  AgreementComponent,
  AuthenticateComponent,
  RegisterComponent,
  ResetPasswordComponent,
  UnauthorizedComponent,
];
export const publicComponents: any[] = [];

export * from './agreement';
export * from './authenticate';
export * from './register';
export * from './reset-password';
export * from './unauthorized';
