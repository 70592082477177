import { AppNavigationItem } from '../models';
import { Permissions } from '@simx/shared/constants';
import { UserPermission } from '@simx/shared/constants';

export const primaryNavigationItems: Array<AppNavigationItem> = [
  {
    label: 'Institutions',
    path: '/institutions',
    permissions: [
      Permissions.AdministerAssociatedInstitutions,
      Permissions.AdministerAllInstitutions,
    ],
  },
  {
    label: 'Users',
    path: '/users',
    permissions: [Permissions.AdministerAllUsers, Permissions.AdministerUsers],
  },
  {
    label: 'Scenarios',
    path: '/scenarios',
    permissions: [
      Permissions.AccessScenarios,
      Permissions.AdministerAllScenarios,
      Permissions.AdministerScenarios,
    ],
  },
  {
    label: 'Scenario Packages',
    path: '/scenario-packages',
    permissions: [Permissions.AccessScenarioPackages],
  },
  {
    label: 'Downloads',
    path: '/downloads',
    permissions: [UserPermission.AccessDownloads],
  },
  {
    label: 'Help',
    path: '/help',
    permissions: [Permissions.AccessHelp],
  },
];
