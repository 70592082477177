import { User } from './user.model';

export class ScenarioRevision {
  createdAt?: string;
  createdByUser?: User;
  createdByUserId?: string;
  dataFile?: string;
  dataFileKey?: string;
  description?: string;
  scenarioId?: string;
  scenarioRevisionId?: string;
  updatedAt?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('createdAt')) {
        this.createdAt = data.createdAt;
      }
      if (data.hasOwnProperty('createdByUser')) {
        this.createdByUser = new User(data.createdByUser);
      }
      if (data.hasOwnProperty('createdByUserId')) {
        this.createdByUserId = data.createdByUserId;
      }
      if (data.hasOwnProperty('dataFile')) {
        this.dataFile = data.dataFile;
      }
      if (data.hasOwnProperty('dataFileKey')) {
        this.dataFileKey = data.dataFileKey;
      }
      if (data.hasOwnProperty('description')) {
        this.description = data.description;
      }
      if (data.hasOwnProperty('scenarioId')) {
        this.scenarioId = data.scenarioId;
      }
      if (data.hasOwnProperty('scenarioRevisionId')) {
        this.scenarioRevisionId = data.scenarioRevisionId;
      }
      if (data.hasOwnProperty('updatedAt')) {
        this.updatedAt = data.updatedAt;
      }
    }
  }
}
