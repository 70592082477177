import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '@simx/modules/authentication/services';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  host: {
    class: 'angular-component-wrapper',
  },
})
export class RegisterComponent implements OnInit, OnDestroy {
  private route$: Subscription;

  registrationInfo: {
    institutionName: string;
    email: string;
  };
  registrationData = {};

  errorMessage: string;

  private registrationToken: string;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.route$ = this.route.params.subscribe(params => {
      this.registrationToken = params['registrationToken'].replace(/---/g, '.');
      // this.authService.getRegistrationInfo(this.registrationToken)
      //   .then(s => {
      //     this.registrationInfo = s;
      //   });
    });
  }

  ngOnDestroy() {
    this.route$ && this.route$.unsubscribe();
  }

  register() {
    this.errorMessage = '';
    const data: any = this.registrationData;

    if (!data.name) {
      return (this.errorMessage = 'You need a name');
    }

    if (!data.password1 || !data.password2) {
      return (this.errorMessage = 'Finish filling out the form please');
    }

    if (data.password1 !== data.password2) {
      return (this.errorMessage = 'Passwords do not match');
    }

    data.password = data.password1;

    // this.authService.register(data, this.registrationToken)
    //   .then((userData: any) => {
    //     const { userId, token } = userData;
    //     this.store.dispatch(AuthenticationStoreActions.setAuthenticatedUser({ userId, token }));
    //     this.router.navigate(['/']);
    //   });
  }
}
