import { Component, Input } from '@angular/core';
import { ViewContainerBorders } from '../../../constants';
import { UIThemes } from '@simx/shared/constants';

@Component({
  selector: 'view-container',
  templateUrl: './view-container.component.html',
  styleUrls: ['./view-container.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class ViewContainerComponent {
  @Input() width: string = '100%';
  @Input() theme: UIThemes = UIThemes.White;
  @Input() border: ViewContainerBorders = null;

  get classes() {
    return [this.theme, this.border ? this.border : ''].join(' ').trim();
  }
}
