import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
  fadeInFromLeftAnimation,
  fadeOutToLeftAnimation,
  fadeInFromRightAnimation,
  fadeOutToRightAnimation,
} from '@simx/shared/animations';
import { AuthenticationService } from '../../services';
import { PasswordResetRequest } from '@simx/shared/models';
import { UIThemes } from '@simx/shared/constants';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInFromLeftOutToLeftAnimation', [
      transition(':enter', [
        useAnimation(fadeInFromLeftAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToLeftAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
    trigger('fadeInFromRightOutToRightAnimation', [
      transition(':enter', [
        useAnimation(fadeInFromRightAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutToRightAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;

  displayResetPasswordActionSuccessMessage: boolean = false;
  displayResetPasswordActionErrorMessage: boolean = false;
  resetPasswordActionInProgress: boolean = false;

  uiThemes = UIThemes;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initializeResetPasswordForm();
  }

  initializeResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  onResetPasswordFormSubmit() {
    this.resetPasswordActionInProgress = true;
    this.displayResetPasswordActionSuccessMessage = false;
    this.displayResetPasswordActionErrorMessage = false;
    this.resetPasswordForm.disable();

    const requestData: PasswordResetRequest = {
      email: this.resetPasswordForm.controls.email.value,
    };

    this.authenticationService
      .resetUserPassword(requestData)
      .then((response: boolean) => {
        this.resetPasswordActionInProgress = false;
        this.resetPasswordForm.enable();
        this.displayResetPasswordActionSuccessMessage = true;
      })
      .catch((error: any) => {
        this.resetPasswordActionInProgress = false;
        this.resetPasswordForm.enable();
        if (error.status === 404) {
          // Display the success message even if user
          // was not found to prevent account phishing
          this.displayResetPasswordActionSuccessMessage = true;
        } else {
          this.displayResetPasswordActionErrorMessage = true;
        }
      });
  }
}
