import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'list-filter-attribute-clear',
  templateUrl: './list-filter-attribute-clear.component.html',
  styleUrls: ['./list-filter-attribute-clear.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class ListFilterAttributeClearComponent {
  @Output('clique') clickEvent: EventEmitter<void> = new EventEmitter<void>();

  handleActionClick() {
    this.clickEvent.emit();
  }
}
