import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './authentication-store.state';
import { Agreement, InstitutionUser, User } from '@simx/shared/models';

const getIsAcceptingAgreement = (state: State): boolean =>
  state.isAcceptingAgreement;
const getIsAuthenticated = (state: State): boolean => state.isAuthenticated;
const getIsAuthenticating = (state: State): boolean => state.isAuthenticating;
const getIsDeletingUserSignedAgreement = (
  state: State,
): { userId: string; agreementId: string } | null =>
  state.isDeletingUserSignedAgreement;
const getIsLoggedIn = (state: State): boolean => state.isLoggedIn;
const getIsLoggingIn = (state: State): boolean => state.isLoggingIn;
const getIsLoggingOut = (state: State): boolean => state.isLoggingOut;
const getToken = (state: State): string => state.token;
const getUnsignedAgreements = (state: State): Array<Agreement> =>
  state.unsignedAgreements;
const getUser = (state: State): User | null => state.user;
const getUserId = (state: State): string => state.user?.userId || null;
const getUserName = (state: State): string => state.user?.name || null;
const getUserPermissions = (state: State): Array<string> =>
  state.user?.userPermissions || [];
const getUserInstitutions = (state: State): Array<InstitutionUser> =>
  state.user?.institutions || [];

export const selectAuthenticationState =
  createFeatureSelector<State>('authentication');

export const selectIsAcceptingAgreement = createSelector(
  selectAuthenticationState,
  getIsAcceptingAgreement,
);
export const selectIsAuthenticated = createSelector(
  selectAuthenticationState,
  getIsAuthenticated,
);
export const selectIsAuthenticating = createSelector(
  selectAuthenticationState,
  getIsAuthenticating,
);
export const selectIsDeletingUserSignedAgreement = createSelector(
  selectAuthenticationState,
  getIsDeletingUserSignedAgreement,
);
export const selectIsLoggedIn = createSelector(
  selectAuthenticationState,
  getIsLoggedIn,
);
export const selectIsLoggingIn = createSelector(
  selectAuthenticationState,
  getIsLoggingIn,
);
export const selectIsLoggingOut = createSelector(
  selectAuthenticationState,
  getIsLoggingOut,
);
export const selectToken = createSelector(selectAuthenticationState, getToken);
export const selectUnsignedAgreements = createSelector(
  selectAuthenticationState,
  getUnsignedAgreements,
);
export const selectUser = createSelector(selectAuthenticationState, getUser);
export const selectUserId = createSelector(
  selectAuthenticationState,
  getUserId,
);
export const selectUserName = createSelector(
  selectAuthenticationState,
  getUserName,
);
export const selectUserPermissions = createSelector(
  selectAuthenticationState,
  getUserPermissions,
);
export const selectUserInstitutions = createSelector(
  selectAuthenticationState,
  getUserInstitutions,
);
