import { Component } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './app.component.html',
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AppComponent {}
