import { Injectable } from '@angular/core';
import { System } from '../../../../system';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  getAppReleaseVersion(): string {
    return System.appReleaseVersion;
  }
}
