export class Download {
  downloadId?: string;
  filename?: string;
  releaseTag?: string;
  url?: string;
  version?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('downloadId')) {
        this.downloadId = data.downloadId;
      }
      if (data.hasOwnProperty('filename')) {
        this.filename = data.filename;
      }
      if (data.hasOwnProperty('releaseTag')) {
        this.releaseTag = data.releaseTag;
      }
      if (data.hasOwnProperty('url')) {
        this.url = data.url;
      }
      if (data.hasOwnProperty('version')) {
        this.version = data.version;
      }
    }
  }
}
