import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccordionListItemViewStates } from '../../../../../constants';

@Component({
  selector: 'accordion-list-item-header',
  templateUrl: './accordion-list-item-header.component.html',
  styleUrls: ['./accordion-list-item-header.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AccordionListItemHeaderComponent {
  get cssClasses() {
    const classes = [];
    classes.push(this.currentViewState);
    if (this.headerOnly) {
      classes.push('simx-header-only');
    }
    return classes;
  }

  @Input('viewState') currentViewState: AccordionListItemViewStates;
  @Input() headerOnly: boolean = false;

  @Output() changeViewState: EventEmitter<AccordionListItemViewStates> =
    new EventEmitter<AccordionListItemViewStates>();

  handleHeaderClick() {
    this.toggleExpandedCollapsedViewState();
  }

  toggleExpandedCollapsedViewState() {
    const newViewState =
      this.currentViewState === AccordionListItemViewStates.Expanded
        ? AccordionListItemViewStates.Collapsed
        : AccordionListItemViewStates.Expanded;
    this.changeViewState.emit(newViewState);
  }
}
