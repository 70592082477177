import {
  AfterContentInit,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { AccordionListItemViewStates } from '../../../constants';
import { AccordionListItemInterface } from '../../../interfaces';

@Component({
  selector: 'accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AccordionListComponent implements AfterContentInit {
  @ContentChildren('accordionListItem')
  itemRefs: QueryList<AccordionListItemInterface>;

  items: Array<AccordionListItemInterface> = [];

  ngAfterContentInit() {
    this.itemRefs.changes.subscribe(() => {
      this.items = this.itemRefs.toArray();
    });
  }

  collapseAll() {
    this.items.forEach(item =>
      item.setListItemViewState(AccordionListItemViewStates.Collapsed),
    );
  }

  expandAll() {
    this.items.forEach(item =>
      item.setListItemViewState(AccordionListItemViewStates.Expanded),
    );
  }
}
