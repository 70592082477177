import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppNavigationService } from '../../services';

@Component({
  selector: 'app-view',
  templateUrl: './app-view.component.html',
  styleUrls: ['./app-view.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
})
export class AppViewComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  accommodateAppHeader: boolean;
  accommodateAppSidebar: boolean;

  constructor(private appNavigationService: AppNavigationService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.appNavigationService.showAppHeader$.subscribe(value => {
        this.accommodateAppHeader = value;
      }),
    );

    this.subscriptions.add(
      this.appNavigationService.showAppSidebar$.subscribe(value => {
        this.accommodateAppSidebar = value;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
