import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'entity-detail-header',
  templateUrl: './entity-detail-header.component.html',
  styleUrls: ['./entity-detail-header.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  encapsulation: ViewEncapsulation.None,
})
export class EntityDetailHeaderComponent {}
