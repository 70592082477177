import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { TaxonomyItem } from '@simx/shared/models';

@Injectable({ providedIn: 'root' })
export class TaxonomyDataService {
  constructor(
    private dataService: DataService,
    private http: HttpClient,
  ) {}

  getTaxonomyByName(taxonomyName: string): Promise<Array<TaxonomyItem>> {
    return new Promise((resolve, reject) => {
      const url = this.dataService.taxonomyUrl(taxonomyName);

      this.http
        .get(url)
        .toPromise()
        .then((response: Array<any>) => {
          const taxonomyItems = response.map(item => new TaxonomyItem(item));

          resolve(taxonomyItems);
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
}
