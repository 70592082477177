import { InstitutionUser } from './institution-user.model';

export class CondensedUser {
  email?: string;
  institutions?: Array<InstitutionUser>;
  name?: string;
  userId?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('email')) {
        this.email = data.email;
      }
      if (data.hasOwnProperty('institutions')) {
        this.institutions = data.institutions.map(
          item => new InstitutionUser(item),
        );
      }
      if (data.hasOwnProperty('name')) {
        this.name = data.name;
      }
      if (data.hasOwnProperty('userId')) {
        this.userId = data.userId;
      }
    }
  }
}
