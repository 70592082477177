import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './institution-store.reducer';
import { InstitutionStoreEffects } from './institution-store.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('institution', reducer),
    EffectsModule.forFeature([InstitutionStoreEffects]),
  ],
  providers: [InstitutionStoreEffects],
})
export class InstitutionStoreModule {}
