import { Scenario } from './scenario.model';
import { ScenarioRelease } from './scenario-release.model';

export class ScenarioPackageItem {
  currentHeadScenarioRelease?: ScenarioRelease;
  releaseTag?: string;
  scenario?: Scenario;
  scenarioId?: string;
  scenarioPackageId?: string;
  scenarioPackageItemId?: string;

  constructor(data?: any) {
    if (data) {
      if (data.hasOwnProperty('currentHeadScenarioRelease')) {
        this.currentHeadScenarioRelease = new ScenarioRelease(
          data.currentHeadScenarioRelease,
        );
      }
      if (data.hasOwnProperty('releaseTag')) {
        this.releaseTag = data.releaseTag;
      }
      if (data.hasOwnProperty('scenario')) {
        this.scenario = new Scenario(data.scenario);
      }
      if (data.hasOwnProperty('scenarioId')) {
        this.scenarioId = data.scenarioId;
      }
      if (data.hasOwnProperty('scenarioPackageId')) {
        this.scenarioPackageId = data.scenarioPackageId;
      }
      if (data.hasOwnProperty('scenarioPackageItemId')) {
        this.scenarioPackageItemId = data.scenarioPackageItemId;
      }
    }
  }
}
