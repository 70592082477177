import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  CorsInterceptor,
  TokenInterceptor,
  UnauthorizedInterceptor,
} from './interceptors';
import { SharedModule } from '@simx/shared/module';
import { AuthenticationModule } from '@simx/modules/authentication';
import { AppRoutingModule } from './routing';
import { allComponents, publicComponents } from './components';

@NgModule({
  declarations: [...allComponents],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
  ],
  exports: [...publicComponents],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
