import { Component, Input } from '@angular/core';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  fadeInStaticPositionAnimation,
  fadeOutStaticPositionAnimation,
} from '@simx/shared/animations';
import {
  EntityDetailDisplayModes,
  EntityDetailDividerStyles,
} from '@simx/shared/constants';
import { LoadingIndicatorStyles } from '@simx/modules/ui-elements/constants';

@Component({
  selector: 'entity-detail',
  templateUrl: './entity-detail.component.html',
  styleUrls: ['./entity-detail.component.scss'],
  host: {
    class: 'angular-component-wrapper',
  },
  animations: [
    trigger('fadeInOutStaticPositionAnimation', [
      transition(':enter', [
        useAnimation(fadeInStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
      transition(':leave', [
        useAnimation(fadeOutStaticPositionAnimation, {
          params: {
            duration: '0.2s',
            delay: '0s',
          },
        }),
      ]),
    ]),
  ],
})
export class EntityDetailComponent {
  @Input() displayMode: EntityDetailDisplayModes;

  @Input('initializing') currentlyInitializing: boolean;
  @Input() initializationErrorState: boolean;
  @Input() initializationErrorMessage: string =
    `An error has occurred, please contact SimX Support for assistance.`;

  loadingIndicatorStyles = LoadingIndicatorStyles;
  entityDetailDisplayModes = EntityDetailDisplayModes;
  entityDetailDividerStyles = EntityDetailDividerStyles;
}
