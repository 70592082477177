import { Action, createReducer, on } from '@ngrx/store';
import { State, initialState } from './taxonomy-store.state';
import * as TaxonomyStoreActions from './taxonomy-store.actions';

const taxonomyReducer = createReducer(
  initialState,
  on(TaxonomyStoreActions.loadTaxonomyRequested, (state, { taxonomyName }) => ({
    ...state,
    isLoadingTaxonomies: [...state.isLoadingTaxonomies, taxonomyName],
  })),
  on(TaxonomyStoreActions.loadTaxonomySucceeded, (state, { taxonomyName }) => {
    const isLoadingTaxonomies = state.isLoadingTaxonomies.filter(
      loadingTaxonomyName => loadingTaxonomyName !== taxonomyName,
    );
    return {
      ...state,
      isLoadingTaxonomies,
    };
  }),
  on(TaxonomyStoreActions.loadTaxonomyFailed, (state, { taxonomyName }) => {
    const isLoadingTaxonomies = state.isLoadingTaxonomies.filter(
      loadingTaxonomyName => loadingTaxonomyName !== taxonomyName,
    );
    return {
      ...state,
      isLoadingTaxonomies,
    };
  }),
  on(
    TaxonomyStoreActions.loadTaxonomy,
    (state, { taxonomyName, taxonomyItems }) => {
      const taxonomies = { ...state.taxonomies };
      taxonomies[taxonomyName] = taxonomyItems;
      return {
        ...state,
        hasLoadedTaxonomies: [...state.hasLoadedTaxonomies, taxonomyName],
        taxonomies,
      };
    },
  ),
  on(TaxonomyStoreActions.clearTaxonomies, () => initialState),
);

export function reducer(state: State | undefined, action: Action) {
  return taxonomyReducer(state, action);
}
